import { Playground, Props } from 'docz';
import { Row } from "../../../../../documentation/components/helpers";
import AdjustableBase from "../../../../../src/Icons/AdjustableBase";
import AdjustableBaseFull from "../../../../../src/Icons/AdjustableBaseFull";
import BedSets from "../../../../../src/Icons/BedSets";
import BoxSpring from "../../../../../src/Icons/BoxSpring";
import MattressWithBoxSpring from "../../../../../src/Icons/MattressBoxSpring";
import PlatformFrame from "../../../../../src/Icons/PlatformFrame";
import StorageBelowBed from "../../../../../src/Icons/StorageBelowBed";
import * as React from 'react';
export default {
  Playground,
  Props,
  Row,
  AdjustableBase,
  AdjustableBaseFull,
  BedSets,
  BoxSpring,
  MattressWithBoxSpring,
  PlatformFrame,
  StorageBelowBed,
  React
};