import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const ExtraPlush: React.FunctionComponent<Props> = ({
  width = '28',
  height = '39',
  fillColor = '#2D2926',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 28 39" {...rest}>
    <path
      d="M4.33225 26.929V27.93H0.328247V21.4263H4.269V22.4273H1.38975V24.1158H4.01875V25.1168H1.38975V26.8685H4.3295L4.33225 26.929C4.33225 26.929 4.3295 26.929 4.33225 26.929Z"
      fill={fillColor}
    />
    <path
      d="M8.89997 27.9933L7.46172 25.6173L6.02347 27.996H4.77222L6.83747 24.6163L4.89872 21.4263H6.14997L7.46447 23.6153L8.77897 21.4263H10.0302L8.09147 24.6163L10.1567 27.996L8.89997 27.9933C8.89997 27.9933 8.89997 27.996 8.89997 27.9933Z"
      fill={fillColor}
    />
    <path
      d="M15.2195 22.4245H13.3412V27.93H12.277V22.4245H10.3987V21.4263H15.1535V22.4273C15.1535 22.4273 15.2195 22.4273 15.2195 22.4245Z"
      fill={fillColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0978 25.554H18.349L19.79 27.9988H20.978L19.4765 25.433C20.2273 25.1195 20.791 24.3688 20.791 23.5548C20.791 22.364 19.8505 21.4263 18.6625 21.4263H16.0335V27.9328H17.0978V25.554ZM18.6625 24.6135H17.0978V22.3585H18.6625C19.2263 22.3585 19.6635 22.859 19.6635 23.486C19.6635 24.113 19.2263 24.6135 18.6625 24.6135Z"
      fill={fillColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7902 26.6183H25.6062L26.0462 27.996H27.2342L24.8555 21.363H23.541L21.1622 27.9328H22.353L22.7902 26.6183ZM24.1652 22.6775L25.2295 25.6173H23.101L24.1652 22.6775Z"
      fill={fillColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.92697 35.0635C4.17822 35.0635 5.11597 34.123 5.11597 32.935C5.11597 31.6838 4.17822 30.7488 2.98747 30.7488H0.548218V37.3158H1.61247V35.0635H2.92697ZM2.86372 31.8075C3.55397 31.8075 3.99122 32.2475 3.99122 32.935C3.99122 33.562 3.55122 34.0625 2.86372 34.123H1.54922V31.8075H2.86372Z"
      fill={fillColor}
    />
    <path d="M9.81021 36.3148V37.3158H6.05646V30.7488H7.12071V36.2543H9.81021V36.3148Z" fill={fillColor} />
    <path
      d="M10.4344 35.1268V30.7461H11.4987V35.0636C11.4987 35.8143 11.9359 36.3781 12.9369 36.3781C13.8747 36.3781 14.3147 35.8143 14.3147 35.0636V30.7461H15.3789V35.1268C15.3789 36.5651 14.3147 37.4423 12.8764 37.4423C11.4354 37.4423 10.4344 36.5651 10.4344 35.1268Z"
      fill={fillColor}
    />
    <path
      d="M16.3167 35.8144L17.2545 35.2506C17.5047 35.9381 18.0052 36.3781 18.8825 36.3781C19.7597 36.3781 20.0705 36.0014 20.0705 35.5009C20.0705 34.9371 19.57 34.7501 18.569 34.4366C17.568 34.1231 16.567 33.7491 16.567 32.4979C16.567 31.3099 17.568 30.6196 18.6955 30.6196C19.7597 30.6196 20.5737 31.1834 21.011 32.0579L20.0732 32.5584C19.823 32.0579 19.4462 31.6206 18.6955 31.6206C18.0685 31.6206 17.6945 31.9341 17.6945 32.4346C17.6945 32.9351 18.0712 33.1854 19.009 33.4356C20.0732 33.8124 21.198 34.1231 21.198 35.4376C21.198 36.6256 20.2602 37.3764 18.8825 37.3764C17.5047 37.4424 16.6302 36.8181 16.3167 35.8144Z"
      fill={fillColor}
    />
    <path
      d="M27.0142 30.7488V37.3185H25.9499V34.5025H23.1339V37.3185H22.0724V30.7515H23.1367V33.441H25.9527V30.7515C25.9527 30.7515 27.0142 30.7515 27.0142 30.7488Z"
      fill={fillColor}
    />
    <path
      d="M23.9178 17.0426C23.4173 17.0426 23.167 16.9161 22.8535 16.7291C22.4135 16.4789 21.9158 16.1626 20.8515 16.1626C19.8505 16.1626 19.35 16.4129 18.9128 16.6631C18.4755 16.8501 18.0988 17.0426 17.348 17.0426C16.5973 17.0426 16.2205 16.8556 15.7833 16.6659C15.346 16.4156 14.7823 16.1626 13.8445 16.1626C12.8435 16.1626 12.343 16.4129 11.9058 16.6631C11.4685 16.8501 11.0918 17.0426 10.341 17.0426C9.5903 17.0426 9.21355 16.8556 8.7763 16.6659C8.33905 16.4156 7.7753 16.1626 6.83755 16.1626C5.7733 16.1626 5.2728 16.4761 4.83555 16.7264C4.52205 16.9134 4.2718 17.0426 3.7713 17.0426C3.52105 17.0426 3.33405 17.2296 3.33405 17.4799C3.33405 17.7301 3.52105 17.9171 3.7713 17.9171C4.52205 17.9171 4.8988 17.6669 5.2728 17.4799C5.64955 17.2324 6.02355 17.0426 6.83755 17.0426C7.5883 17.0426 7.96505 17.2296 8.4023 17.4194C8.83955 17.6669 9.4033 17.9199 10.341 17.9199C11.342 17.9199 11.8425 17.6696 12.2798 17.4194C12.717 17.2324 13.0938 17.0426 13.8445 17.0426C14.5953 17.0426 14.972 17.2296 15.4093 17.4194C15.8465 17.6669 16.4103 17.9199 17.348 17.9199C18.349 17.9199 18.8495 17.6696 19.2868 17.4194C19.724 17.2324 20.1008 17.0426 20.8515 17.0426C21.6655 17.0426 22.0395 17.2296 22.4163 17.4799C22.793 17.6669 23.2303 17.9171 23.9178 17.9171C24.168 17.9171 24.355 17.7301 24.355 17.4799C24.355 17.2956 24.168 17.0426 23.9178 17.0426Z"
      fill={fillColor}
    />
    <path
      d="M4.70902 14.0396C4.39552 14.2239 4.14527 14.3531 3.64477 14.3531C3.39452 14.3531 3.20752 14.5401 3.20752 14.7904C3.20752 15.0406 3.39452 15.2276 3.64477 15.2276C4.39552 15.2276 4.77227 14.9774 5.14627 14.7904C5.52302 14.5429 5.89702 14.3531 6.71102 14.3531C7.46177 14.3531 7.83852 14.5401 8.27577 14.7299C8.71302 14.9801 9.27677 15.2331 10.2145 15.2331C11.2155 15.2331 11.716 14.9829 12.1533 14.7326C12.5905 14.5456 12.9673 14.3531 13.718 14.3531C14.4688 14.3531 14.8455 14.5401 15.2828 14.7299C15.72 14.9801 16.2838 15.2331 17.2215 15.2331C18.2225 15.2331 18.723 14.9829 19.1603 14.7326C19.5975 14.5456 19.9743 14.3531 20.725 14.3531C21.539 14.3531 21.913 14.5401 22.2898 14.7904C22.6665 14.9774 23.1038 15.2276 23.7913 15.2276C24.0415 15.2276 24.2285 15.0406 24.2285 14.7904C24.2285 14.5401 24.0415 14.3531 23.7913 14.3531C23.2908 14.3531 23.0405 14.2266 22.727 14.0396C22.2898 13.7894 21.7893 13.4759 20.725 13.4759C19.724 13.4759 19.2235 13.7261 18.7863 13.9764C18.349 14.1634 17.9723 14.3531 17.2215 14.3531C16.4708 14.3531 16.094 14.1661 15.6568 13.9764C15.2195 13.7289 14.6558 13.4759 13.718 13.4759C12.717 13.4759 12.2165 13.7261 11.7793 13.9764C11.342 14.1634 10.9653 14.3531 10.2145 14.3531C9.46377 14.3531 9.08702 14.1661 8.64977 13.9764C8.21252 13.7289 7.64877 13.4759 6.71102 13.4759C5.71002 13.4759 5.14627 13.7866 4.70902 14.0396Z"
      fill={fillColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9703 11.4712C15.9703 11.7214 15.7833 11.9084 15.533 11.9084C15.2828 11.9084 15.0958 11.6609 15.093 11.4739V0.523432C15.093 0.273182 15.28 0.0861816 15.5303 0.0861816H19.3473C21.9763 0.0861816 23.728 1.40068 23.728 3.65293V3.71343C23.728 6.21593 21.5995 7.46718 19.1603 7.46718H15.9703V11.4712ZM19.2868 0.900182H16.0335V6.65593H19.2235C21.352 6.65593 22.8535 5.52843 22.8535 3.77668V3.71618C22.8535 1.90118 21.4153 0.900182 19.2868 0.900182Z"
      fill={fillColor}
    />
    <path
      d="M12.7803 11.7848C13.0305 11.7848 13.2175 11.5978 13.2175 11.3475C13.2175 11.221 13.1543 11.0973 13.0305 10.9708L9.08977 5.83928L12.9068 0.958025C12.97 0.894775 13.0333 0.771025 13.0333 0.644525C13.0333 0.394275 12.8463 0.207275 12.596 0.207275C12.409 0.207275 12.2825 0.333775 12.1588 0.457525L8.52877 5.21228L4.83827 0.457525C4.77502 0.331025 4.65127 0.207275 4.46152 0.207275C4.21127 0.207275 4.02427 0.394275 4.02427 0.644525C4.02427 0.771025 4.08752 0.894775 4.21127 1.02128L8.02827 5.90253L4.02427 11.0313C3.96102 11.0973 3.89777 11.221 3.89777 11.3475C3.89777 11.5978 4.08477 11.7848 4.33502 11.7848C4.52202 11.7848 4.64852 11.6583 4.77227 11.5345L8.58927 6.59278L12.4695 11.5978C12.4668 11.661 12.5933 11.7848 12.7803 11.7848Z"
      fill={fillColor}
    />
  </svg>
)

export default ExtraPlush
