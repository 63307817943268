import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  secondaryColor?: string
  [x: string]: unknown
}

const SertaBrand: React.FunctionComponent<Props> = ({
  width = 69,
  height = 54,
  fillColor = '#FAE100',
  secondaryColor = '#00263E',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 69 54" {...rest}>
    <g fillRule="nonzero">
      <path
        fill={fillColor}
        d="M64.868 17.946c-.22-.275-.494-.055-.33.164 1.592 1.976 4.501 8.232.11 15.86-4.006 6.97-14.158 14.599-31.61 13.995-14.434-.494-27.989-9.384-27.88-21.293.11-10.428 9.934-24.477 29.416-25.08 2.58-.055 6.147-.055 8.891.384 6.86 1.042 10.098 2.14 13.007 3.457.384.165.384-.11.164-.274C54.441 3.842 48.404.549 36.77.274 19.812-.11 2.744 9.714.494 26.342c-2.14 16.025 15.147 27.276 33.696 27.66 18.55.384 32.544-12.074 33.806-24.751.824-7.573-3.128-11.305-3.128-11.305z"
      />
      <path
        fill={secondaryColor}
        d="M55.758 37.099V21.623c0-1.756-2.03-2.909-3.732-2.909-1.591 0-3.732 1.098-3.732 2.909v2.963h2.854v-2.14c0-.823.604-.988.878-.988.275 0 .823.165.823.988 0 .549.055.274 0 .933-.054.658-.878 1.427-1.481 1.81-.604.44-1.153.989-1.811 1.483-.604.494-1.263 1.372-1.263 2.799 0 1.372.055 4.006 0 5.542-.054 1.537 1.647 2.14 2.69 2.086.768 0 1.591-.494 1.92-.878v.878h2.854zm-2.909-3.622c0 .494-.439.713-.823.713-.384 0-.823-.22-.878-.713-.055-.549 0-3.293 0-3.952 0-.603.11-.823.275-1.042.164-.22 1.426-1.098 1.426-1.098v6.092zM44.782 37.099V22.117h2.47v-2.909h-2.47v-4.17h-2.854v4.17h-1.591v2.909h1.591v14.982zM34.08 19.098v18.056h2.91V23.708c0-.933.713-1.536 2.03-1.536v-2.964c-1.098 0-1.427.165-2.03.713v-.823h-2.91zM32.654 28.922v-7.3c0-2.14-2.47-2.853-3.787-2.853-1.317 0-3.677.713-3.677 2.854V34.19c0 2.47 2.195 3.183 3.732 3.128 1.756-.054 3.677-.713 3.677-3.128v-3.073H29.69v2.36c0 .549-.439.768-.768.768-.33 0-.823-.274-.823-.768v-4.555h4.555zm-4.555-6.202c0-.713.603-.823.768-.823.22 0 .823.165.823.823v3.403H28.1V22.72zM15.64 28.922v5.158c0 .714.934 3.238 4.007 3.238 3.073 0 4.006-1.756 4.006-3.238v-5.597c0-.823-.329-2.36-1.591-3.348-.933-.768-1.427-1.152-1.427-1.152s-1.098-.988-1.537-1.372c-.658-.659-.658-1.592-.658-2.086v-3.128c0-.549.658-.878 1.097-.878.44 0 1.098.274 1.098.878v4.006h2.963V16.52c0-.714-.933-3.238-4.006-3.238-3.128 0-4.006 1.756-4.006 3.238v5.323c0 .823.33 2.36 1.591 3.348.933.768 1.263 1.098 1.263 1.098s1.207 1.042 1.646 1.481c.659.659.659 1.592.659 2.086v3.402c0 .55-.659.878-1.098.878-.439 0-1.098-.329-1.098-.878v-4.28h-2.908v-.055zM66.624 43.794c-1.207 0-2.195.933-2.195 2.14 0 1.263 1.043 2.14 2.195 2.14 1.208 0 2.196-.932 2.196-2.14 0-1.207-1.043-2.14-2.196-2.14zm0 3.952a1.792 1.792 0 0 1-1.81-1.811c0-.988.768-1.811 1.81-1.811.988 0 1.756.768 1.756 1.81 0 1.043-.768 1.812-1.756 1.812z"
      />
      <path
        fill={secondaryColor}
        d="M67.612 45.44c0-.493-.274-.713-.878-.713h-.933v2.47h.384v-1.098h.44l.658 1.098h.439l-.714-1.098c.33-.055.604-.22.604-.658zm-1.482.385v-.769h.55c.274 0 .548.055.548.385 0 .384-.274.384-.604.384h-.494z"
      />
    </g>
  </svg>
)

export default SertaBrand
