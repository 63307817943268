import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Pagination } from "../../../../../src";
import { Row } from "../../../../../documentation/components/helpers";
import * as React from 'react';
export default {
  Playground,
  Props,
  Component,
  Pagination,
  Row,
  React
};