import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const Mattresses: React.FunctionComponent<Props> = ({
  width = '24',
  height = '24',
  fillColor = '#2D2926',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...rest}>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M21.93 9.31h-8.4c-.408.003-.8.168-1.086.458-.287.29-.447.684-.444 1.092v11.59c-.003.409.157.801.444 1.092.287.29.678.455 1.086.458h8.4c.847-.01 1.526-.703 1.52-1.55V10.86c.006-.846-.673-1.539-1.52-1.55zm.51 13.14c0 .284-.227.515-.51.52h-8.4c-.135-.002-.264-.059-.358-.156-.094-.098-.145-.229-.142-.364V10.86c-.003-.134.049-.264.143-.36.094-.096.223-.15.357-.15h8.4c.282 0 .51.229.51.51v11.59zm-11.33-2.6H9.58c-.211-.005-.38-.178-.38-.39V7.62c0-.211.169-.384.38-.39h8.64c.213.005.385.177.39.39v.81c-.04.203.045.41.216.524.172.115.396.115.568 0 .171-.114.257-.321.216-.524v-.81c.003-.374-.143-.734-.406-1-.262-.266-.62-.417-.994-.42H9.58c-.374.003-.732.154-.994.42-.263.266-.409.626-.406 1v11.84c-.006.78.62 1.42 1.4 1.43h1.53c.283-.005.51-.236.51-.52.003-.137-.05-.27-.146-.367-.096-.098-.227-.153-.364-.153zm-3.83-3.11H5.75c-.217-.005-.39-.183-.39-.4V4.51c.005-.213.177-.385.39-.39h8.64c.213.005.385.177.39.39v.8c-.04.203.045.41.216.525.172.114.396.114.568 0 .171-.115.257-.322.216-.525v-.8c.006-.78-.62-1.419-1.4-1.43H5.75c-.78.011-1.406.65-1.4 1.43v11.83c-.006.78.62 1.42 1.4 1.43h1.53c.223-.064.377-.268.377-.5 0-.232-.154-.436-.377-.5v-.03zm-3.8-3.09H2c-.215 0-.39-.175-.39-.39V1.43c-.008-.11.029-.217.103-.299.073-.081.177-.128.287-.13h8.64c.217.005.39.182.39.4v.8c-.04.202.045.409.216.523.172.115.396.115.568 0 .171-.114.257-.321.216-.524v-.8c-.005-.376-.16-.736-.43-.998-.27-.263-.633-.407-1.01-.402H2C1.22.011.594.65.6 1.43v11.83c-.006.78.62 1.42 1.4 1.43h1.48c.139.003.273-.051.37-.15.099-.097.153-.231.15-.37 0-.287-.233-.52-.52-.52z"
      clipRule="evenodd"
    />
  </svg>
)

export default Mattresses
