import { Playground, Props } from 'docz';
import { Row } from "../../../../../documentation/components/helpers";
import ExtraFirmBed from "../../../../../src/Icons/ExtraFirmBed";
import FirmBed from "../../../../../src/Icons/FirmBed";
import MediumBed from "../../../../../src/Icons/MediumBed";
import PlushBed from "../../../../../src/Icons/PlushBed";
import UltraPlushBed from "../../../../../src/Icons/UltraPlushBed";
import ExtraFirm from "../../../../../src/Icons/ExtraFirm";
import Firm from "../../../../../src/Icons/Firm";
import ExtraPlush from "../../../../../src/Icons/ExtraPlush";
import Medium from "../../../../../src/Icons/Medium";
import Plush from "../../../../../src/Icons/Plush";
import * as React from 'react';
export default {
  Playground,
  Props,
  Row,
  ExtraFirmBed,
  FirmBed,
  MediumBed,
  PlushBed,
  UltraPlushBed,
  ExtraFirm,
  Firm,
  ExtraPlush,
  Medium,
  Plush,
  React
};