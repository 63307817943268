import { Playground, Props } from 'docz';
import { Row } from "../../../../../documentation/components/helpers";
import Facebook from "../../../../../src/Icons/Facebook";
import Instagram from "../../../../../src/Icons/Instagram";
import Pinterest from "../../../../../src/Icons/Pinterest";
import Twitter from "../../../../../src/Icons/Twitter";
import Snapchat from "../../../../../src/Icons/Snapchat";
import Youtube from "../../../../../src/Icons/Youtube";
import * as React from 'react';
export default {
  Playground,
  Props,
  Row,
  Facebook,
  Instagram,
  Pinterest,
  Twitter,
  Snapchat,
  Youtube,
  React
};