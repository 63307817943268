import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const Craftmanship: React.FunctionComponent<Props> = ({
  width = '24',
  height = '25',
  fillColor = '#2D2926',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 24 25" {...rest}>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M19.83 3l1.4 21.52c.008.125.061.242.15.33.142.142.354.188.542.117.188-.072.316-.246.328-.447L23.89 3c0-1.12-.909-2.03-2.03-2.03-1.121 0-2.03.91-2.03 2.03zm1.32-.7c.187-.19.443-.299.71-.3h.02c.552 0 1 .448 1 1l-1.1 16.51-.93-16.52c.006-.26.114-.508.3-.69zm.34 3.24c.146.146.365.189.555.11.19-.079.314-.264.315-.47V3.75c0-.281-.228-.51-.51-.51-.134-.002-.264.049-.36.143-.096.094-.15.223-.15.357v1.44c0 .135.054.265.15.36zm-3.26 9.37c.556 1.343.606 2.843.14 4.22-.32 1.225-1.232 2.21-2.43 2.62 1.33.57 2.18 1.33 2.18 2.33 0 .276-.224.5-.5.5h-17c-.276 0-.5-.224-.5-.5 0-1.37 1.69-2.36 4-2.93v-1.57h-.51c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h.51v-1h-.51c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h.51v-1h-.51c-.274-.005-.495-.226-.5-.5 0-.276.224-.5.5-.5h.51v-1h-.51c-.274-.005-.495-.226-.5-.5 0-.276.224-.5.5-.5h.51v-1h-.51c-.276 0-.5-.224-.5-.5.005-.274.226-.495.5-.5h.51v-1h-.51c-.276 0-.5-.224-.5-.5.005-.274.226-.494.5-.5h.51v-1h-.51c-.276 0-.5-.224-.5-.5.005-.274.226-.494.5-.5h.51V5.02c-2.29-.57-4-1.56-4-2.94 0-.276.224-.5.5-.5h17c.276 0 .5.224.5.5 0 1.38-1.69 2.37-4 2.94v1.57h.5c.276 0 .5.224.5.5s-.224.5-.5.5h-.5v1h.5c.276 0 .5.224.5.5s-.224.5-.5.5h-.5v1h.5c.276 0 .5.224.5.5s-.224.5-.5.5h-.5v1h.5c.276 0 .5.224.5.5s-.224.5-.5.5h-.5v1h.5c.276 0 .5.224.5.5s-.224.5-.5.5h-.5v1h.5c.276 0 .5.224.5.5s-.224.5-.5.5h-.5v1h.5c.276 0 .5.224.5.5s-.224.5-.5.5h-.5V21c1.488.205 2.898-.722 3.3-2.17.399-1.183.349-2.471-.14-3.62-.722-1.893-.722-3.986 0-5.88.093-.252.369-.386.624-.302.256.083.4.354.326.612-.652 1.696-.652 3.574 0 5.27zM16.92 2.58c-.77 1-3.6 2-7.8 2-4.2 0-7-1-7.8-2h15.6zm-7.8 3c-1.341.002-2.68-.115-4-.35v1.35h8V5.23c-1.32.235-2.659.352-4 .35zm4 2v1h-8v-1h8zm0 3v-1h-8v1h8zm0 1v1h-8v-1h8zm0 2h-8v1h8v-1zm0 2v1h-8v-1h8zm0 3v-1h-8v1h8zm0 1v1.34h-.27c-2.558-.44-5.172-.44-7.73 0v-1.34h8zm-4 2c-4.21 0-7.03 1.05-7.8 2h15.6c-.8-.95-3.59-2-7.8-2z"
      clipRule="evenodd"
    />
  </svg>
)

export default Craftmanship
