import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const UltraPlushBed: React.FunctionComponent<Props> = ({
  width = '24',
  height = '14',
  fillColor = '#2D2926',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 24 14" {...rest}>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M14.75 1.03c.956-.37 1.975-.55 3-.53 1.036-.032 2.069.138 3.04.5.848.346 1.754.526 2.67.53.276 0 .5.224.5.5s-.224.5-.5.5c-1.025.017-2.043-.163-3-.53-.874-.335-1.805-.495-2.74-.47-.929-.022-1.852.138-2.72.47-.956.37-1.975.55-3 .53-1.025.02-2.044-.16-3-.53-.89-.353-1.843-.523-2.8-.5-.922-.023-1.84.137-2.7.47-.957.367-1.975.547-3 .53-.276 0-.5-.224-.5-.5s.224-.5.5-.5c.95.02 1.896-.15 2.78-.5.956-.37 1.975-.55 3-.53 1.025-.02 2.044.16 3 .53.865.343 1.79.512 2.72.5.939.025 1.873-.134 2.75-.47zm0 2c.956-.37 1.975-.55 3-.53 1.036-.032 2.069.138 3.04.5.838.343 1.734.523 2.64.53.276 0 .5.224.5.5s-.224.5-.5.5c-1.025.017-2.043-.163-3-.53-.864-.331-1.785-.49-2.71-.47-.929-.022-1.852.138-2.72.47-.956.37-1.975.55-3 .53-1.025.02-2.044-.16-3-.53-.867-.354-1.794-.534-2.73-.53-.922-.023-1.84.137-2.7.47-.957.367-1.975.547-3 .53-.276 0-.5-.224-.5-.5s.224-.5.5-.5c.925.02 1.846-.139 2.71-.47.956-.37 1.975-.55 3-.53 1.025-.02 2.044.16 3 .53.865.343 1.79.512 2.72.5.939.025 1.873-.134 2.75-.47zM.5 5.5c-.276 0-.5.224-.5.5v5c0 .276.224.5.5.5H1v2c0 .276.224.5.5.5s.5-.224.5-.5v-2h20v2c0 .276.224.5.5.5s.5-.224.5-.5v-2h.5c.276 0 .5-.224.5-.5V6c0-.276-.224-.5-.5-.5H.5zm.5 1v4h22v-4H1z"
      clipRule="evenodd"
    />
  </svg>
)

export default UltraPlushBed
