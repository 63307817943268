import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Chip, Button } from "../../../../../src";
import Check from "../../../../../src/Icons/Check.tsx";
import * as React from 'react';
export default {
  Playground,
  Props,
  Component,
  Chip,
  Button,
  Check,
  React
};