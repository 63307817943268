import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const Breathability: React.FunctionComponent<Props> = ({
  width = '24',
  height = '22',
  fillColor = '#2D2926',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 24 22" {...rest}>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M7.45 3.31l-2-3.08c-.025-.038-.055-.072-.09-.1H5.3L5.19 0h-.28c-.176.028-.323.151-.38.32l-2 3c-.07.112-.09.248-.058.376.032.128.114.237.228.304.087.061.194.09.3.08.169.006.329-.078.42-.22L4.5 2.19v8.31c0 .276.224.5.5.5s.5-.224.5-.5V2.15l1.11 1.71c.094.14.252.222.42.22.096.002.19-.026.27-.08.232-.15.299-.458.15-.69zM5 16c-.276 0-.5-.224-.5-.5v-2c0-.276.224-.5.5-.5s.5.224.5.5v2c0 .276-.224.5-.5.5zm7 6c-.276 0-.5-.224-.5-.5v-8c0-.276.224-.5.5-.5s.5.224.5.5v8c0 .276-.224.5-.5.5zm6.5-2.5c0 .276.224.5.5.5s.5-.224.5-.5v-6c0-.276-.224-.5-.5-.5s-.5.224-.5.5v6zm.95-17.36l2 3.09c.069.115.088.253.055.383-.034.13-.119.24-.235.307-.082.05-.175.077-.27.08-.16-.007-.307-.093-.39-.23l-1.11-1.7v6.43c0 .276-.224.5-.5.5s-.5-.224-.5-.5V4.11l-1.08 1.67c-.095.138-.252.22-.42.22-.106.004-.21-.024-.3-.08-.112-.073-.19-.189-.217-.32-.026-.132.001-.269.077-.38l2.09-3.13.09-.09H19.35l.1.14zm-5 5.09l-2-3.09-.1-.14H11.74l-.09.09-2 3.08c-.076.111-.103.248-.077.38.026.131.105.247.217.32.059.06.13.104.21.13.168 0 .325-.082.42-.22l1.08-1.67v4.39c0 .276.224.5.5.5s.5-.224.5-.5V6.07l1.11 1.7c.083.137.23.223.39.23.095-.003.188-.03.27-.08.116-.067.2-.178.235-.307.034-.13.014-.268-.055-.383zM.46 11.5c-.254 0-.46.206-.46.46v.08c0 .254.206.46.46.46h23.08c.254 0 .46-.206.46-.46v-.08c0-.254-.206-.46-.46-.46H.46z"
      clipRule="evenodd"
    />
  </svg>
)

export default Breathability
