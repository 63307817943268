import { Playground, Props } from 'docz';
import { Row } from "../../../../../documentation/components/helpers";
import UPS from "../../../../../src/Icons/CarrierUPS";
import FedEx from "../../../../../src/Icons/CarrierFedex";
import USPS from "../../../../../src/Icons/CarrierUSPS";
import DefaultCarrier from "../../../../../src/Icons/CarrierDefault";
import * as React from 'react';
export default {
  Playground,
  Props,
  Row,
  UPS,
  FedEx,
  USPS,
  DefaultCarrier,
  React
};