import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const ChatBubble: React.FunctionComponent<Props> = ({
  width = '24',
  height = '20',
  fillColor = '#D63426',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 30 25" fill="none" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.9999 17.7713V6.66284C29.9999 4.96139 28.598 3.57715 26.8749 3.57715H23.1249C22.7792 3.57715 22.4999 3.85301 22.4999 4.19429C22.4999 4.53556 22.7792 4.81142 23.1249 4.81142H26.8749C27.9086 4.81142 28.7499 5.64209 28.7499 6.66284V17.7713C28.7499 18.7921 27.9086 19.6227 26.8749 19.6227H24.3749C24.0292 19.6227 23.7499 19.8986 23.7499 20.2399V22.7084L19.7499 19.7462C19.6417 19.6659 19.5105 19.6227 19.3749 19.6227H11.8749C11.4861 19.6227 11.1092 19.5018 10.7867 19.2722C10.5055 19.0723 10.1155 19.1364 9.91362 19.4129C9.71237 19.69 9.77674 20.0751 10.0567 20.2751C10.5936 20.6558 11.2224 20.857 11.8749 20.857H19.1667L23.9999 24.4364C24.1099 24.5185 24.2417 24.5598 24.3749 24.5598C24.4699 24.5598 24.5661 24.5382 24.6542 24.4951C24.8661 24.3901 24.9999 24.1766 24.9999 23.9427V20.857H26.8749C28.598 20.857 29.9999 19.4728 29.9999 17.7713Z"
      fill={fillColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 14.0685C20 15.0893 19.1588 15.92 18.125 15.92H10.625C10.4894 15.92 10.3581 15.9632 10.25 16.0434L7.04757 18.415C6.71758 18.6594 6.25 18.4238 6.25 18.0132V16.5371C6.25 16.1958 5.97063 15.92 5.625 15.92H3.125C2.09125 15.92 1.25 15.0893 1.25 14.0685V2.96006C1.25 1.93931 2.09125 1.10864 3.125 1.10864H18.125C19.1588 1.10864 20 1.93931 20 2.96006V14.0685Z"
      stroke={fillColor}
      strokeWidth="1.5"
    />
  </svg>
)

export default ChatBubble
