import { Playground, Props } from 'docz';
import { Row } from "../../../../../documentation/components/helpers";
import BrandTag from "../../../../../src/Icons/BrandTag";
import Comfort from "../../../../../src/Icons/Comfort";
import Ruler from "../../../../../src/Icons/Ruler";
import * as React from 'react';
export default {
  Playground,
  Props,
  Row,
  BrandTag,
  Comfort,
  Ruler,
  React
};