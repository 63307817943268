import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const DualCooling: React.FunctionComponent<Props> = ({
  width = '50',
  height = '50',
  fillColor = '#2D2926',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 50 50" {...rest}>
    <path
      fill={fillColor}
      fillRule="evenodd"
      clipRule="evenodd"
      x="0px"
      y="0px"
      d="M17.79,16.52c0.31,0,0.55-0.25,0.55-0.55v-1.32l1.42,0.71c0.08,0.04,0.16,0.06,0.25,0.06c0.2,0,0.4-0.11,0.5-0.31
	c0.14-0.27,0.03-0.61-0.25-0.74l-1.23-0.61l1.23-0.61c0.27-0.14,0.39-0.47,0.25-0.74c-0.14-0.27-0.47-0.38-0.74-0.25l-1.42,0.71
	v-1.32c0-0.31-0.25-0.55-0.55-0.55c-0.31,0-0.55,0.25-0.55,0.55v1.32l-1.41-0.71c-0.27-0.14-0.61-0.03-0.74,0.25
	c-0.14,0.27-0.03,0.61,0.25,0.74l1.23,0.61l-1.23,0.61c-0.27,0.14-0.38,0.47-0.25,0.74c0.1,0.19,0.29,0.31,0.5,0.31
	c0.08,0,0.17-0.02,0.25-0.06l1.41-0.71v1.32C17.23,16.28,17.48,16.52,17.79,16.52 M21.67,16.22l-3.88,2.43l-3.88-2.43v-4.93
	l3.88-2.43l3.88,2.43V16.22z M29.16,19.93l-1.77-1.03l1.83-1.37c0.25-0.18,0.29-0.53,0.11-0.78c-0.18-0.25-0.53-0.29-0.78-0.11
	l-2.2,1.65l-1.04-0.61l1.69-1.27c0.25-0.18,0.29-0.53,0.11-0.78c-0.18-0.25-0.53-0.29-0.78-0.11l-2.06,1.55l-1.49-0.87V11.3
	l1.49-0.87l2.06,1.55c0.1,0.07,0.22,0.11,0.33,0.11c0.17,0,0.34-0.08,0.44-0.22c0.18-0.25,0.13-0.59-0.11-0.78L25.3,9.83l1.04-0.61
	l2.2,1.65c0.1,0.07,0.22,0.11,0.33,0.11c0.17,0,0.34-0.08,0.44-0.22c0.18-0.24,0.13-0.59-0.11-0.78l-1.83-1.37l1.77-1.03
	c0.26-0.15,0.35-0.49,0.2-0.76c-0.15-0.26-0.49-0.35-0.76-0.2l-1.38,0.81V5.99c0-0.31-0.25-0.55-0.55-0.55
	c-0.31,0-0.55,0.25-0.55,0.55v2.08l-1.11,0.65V7.1c0-0.31-0.25-0.56-0.55-0.56s-0.55,0.25-0.55,0.56v2.27l-1.65,0.97L18.34,7.9V6.33
	l1.91-0.96c0.27-0.14,0.38-0.47,0.25-0.74c-0.14-0.27-0.47-0.38-0.74-0.25l-1.42,0.71V4.12l1.91-0.96c0.27-0.14,0.38-0.47,0.25-0.75
	c-0.14-0.27-0.47-0.38-0.74-0.25l-1.42,0.71V1.55c0-0.31-0.25-0.55-0.55-0.55c-0.31,0-0.55,0.25-0.55,0.55v1.32l-1.41-0.71
	c-0.27-0.14-0.61-0.03-0.74,0.25c-0.14,0.27-0.03,0.61,0.25,0.75l1.91,0.96v0.98l-1.41-0.71c-0.27-0.14-0.61-0.03-0.74,0.25
	c-0.14,0.27-0.03,0.61,0.25,0.74l1.91,0.96V7.9l-3.89,2.43l-1.65-0.97V7.1c0-0.31-0.25-0.56-0.56-0.56c-0.31,0-0.55,0.25-0.55,0.56
	v1.62L9.47,8.07V5.99c0-0.31-0.25-0.55-0.55-0.55c-0.31,0-0.55,0.25-0.55,0.55v1.44L6.98,6.62c-0.26-0.15-0.6-0.06-0.76,0.2
	c-0.15,0.26-0.06,0.6,0.2,0.76l1.77,1.03L6.36,9.98c-0.25,0.18-0.29,0.53-0.11,0.78c0.11,0.15,0.28,0.22,0.44,0.22
	c0.12,0,0.23-0.04,0.33-0.11l2.2-1.65l1.04,0.61l-1.69,1.27c-0.24,0.18-0.29,0.53-0.11,0.78c0.11,0.14,0.28,0.22,0.44,0.22
	c0.12,0,0.23-0.04,0.33-0.11l2.06-1.55l1.49,0.87v4.91l-1.49,0.87l-2.06-1.55C9,15.34,8.66,15.39,8.47,15.64
	c-0.18,0.24-0.13,0.59,0.11,0.78l1.69,1.27l-1.04,0.61l-2.2-1.65c-0.24-0.18-0.59-0.13-0.78,0.11c-0.18,0.25-0.13,0.59,0.11,0.78
	l1.83,1.37l-1.77,1.03c-0.26,0.15-0.35,0.49-0.2,0.76c0.1,0.18,0.29,0.28,0.48,0.28c0.09,0,0.19-0.02,0.28-0.08l1.38-0.81v1.44
	c0,0.31,0.25,0.55,0.55,0.55c0.31,0,0.55-0.25,0.55-0.55v-2.08l1.11-0.65v1.62c0,0.31,0.25,0.55,0.55,0.55
	c0.31,0,0.56-0.25,0.56-0.55v-2.27l1.65-0.96l3.89,2.43v1.57l-1.91,0.96c-0.27,0.14-0.38,0.47-0.25,0.74
	c0.14,0.27,0.47,0.38,0.74,0.25l1.41-0.71v0.98l-1.91,0.96c-0.27,0.14-0.38,0.47-0.25,0.74c0.14,0.27,0.47,0.38,0.74,0.25l1.41-0.71
	v1.32c0,0.31,0.25,0.55,0.55,0.55c0.31,0,0.55-0.25,0.55-0.55v-1.32l1.42,0.71c0.08,0.04,0.16,0.06,0.25,0.06
	c0.2,0,0.4-0.11,0.5-0.31c0.14-0.27,0.03-0.61-0.25-0.74l-1.91-0.96v-0.98l1.42,0.71c0.08,0.04,0.16,0.06,0.25,0.06
	c0.2,0,0.4-0.11,0.5-0.31c0.14-0.27,0.03-0.61-0.25-0.74l-1.91-0.96V19.6l3.89-2.43l1.65,0.96v2.27c0,0.31,0.25,0.55,0.55,0.55
	s0.55-0.25,0.55-0.55v-1.62l1.11,0.65v2.08c0,0.31,0.25,0.55,0.55,0.55c0.31,0,0.55-0.25,0.55-0.55v-1.44l1.38,0.81
	c0.09,0.05,0.18,0.08,0.28,0.08c0.19,0,0.38-0.1,0.48-0.28C29.51,20.42,29.42,20.08,29.16,19.93 M32.21,39.02
	c0.31,0,0.55-0.25,0.55-0.55v-1.32l1.42,0.71c0.08,0.04,0.16,0.06,0.25,0.06c0.2,0,0.4-0.11,0.5-0.31c0.14-0.27,0.03-0.61-0.25-0.74
	l-1.23-0.61l1.23-0.61c0.27-0.14,0.38-0.47,0.25-0.74c-0.14-0.27-0.47-0.38-0.74-0.25l-1.42,0.71v-1.32c0-0.31-0.25-0.55-0.55-0.55
	c-0.31,0-0.55,0.25-0.55,0.55v1.32l-1.42-0.71c-0.27-0.14-0.61-0.03-0.74,0.25c-0.14,0.27-0.03,0.61,0.25,0.74l1.23,0.61l-1.23,0.61
	c-0.27,0.14-0.38,0.47-0.25,0.74c0.1,0.19,0.29,0.31,0.5,0.31c0.08,0,0.17-0.02,0.25-0.06l1.42-0.71v1.32
	C31.66,38.77,31.91,39.02,32.21,39.02 M36.1,38.71l-3.88,2.43l-3.88-2.43v-4.93l3.88-2.43l3.88,2.43V38.71z M43.78,29.31
	c-0.15-0.26-0.49-0.35-0.76-0.2l-1.38,0.81v-1.44c0-0.31-0.25-0.55-0.55-0.55c-0.31,0-0.55,0.25-0.55,0.55v2.08l-1.11,0.65v-1.62
	c0-0.31-0.25-0.55-0.55-0.55c-0.31,0-0.55,0.25-0.55,0.55v2.27l-1.65,0.96l-3.89-2.43v-1.57l1.91-0.96
	c0.27-0.14,0.38-0.47,0.25-0.75c-0.14-0.27-0.47-0.38-0.74-0.25l-1.42,0.71v-0.98l1.91-0.96c0.27-0.14,0.38-0.47,0.25-0.74
	c-0.14-0.27-0.47-0.38-0.74-0.25l-1.42,0.71v-1.32c0-0.31-0.25-0.55-0.55-0.55c-0.31,0-0.55,0.25-0.55,0.55v1.32l-1.42-0.71
	c-0.27-0.14-0.61-0.03-0.74,0.25c-0.14,0.27-0.03,0.61,0.25,0.74l1.91,0.96v0.98l-1.42-0.71c-0.27-0.14-0.61-0.03-0.74,0.25
	c-0.14,0.27-0.03,0.61,0.25,0.75l1.91,0.96v1.57l-3.89,2.43l-1.65-0.96v-2.27c0-0.31-0.25-0.55-0.55-0.55s-0.55,0.25-0.55,0.55v1.62
	l-1.11-0.65v-2.08c0-0.31-0.25-0.55-0.55-0.55s-0.55,0.25-0.55,0.55v1.44l-1.38-0.81c-0.26-0.15-0.6-0.06-0.76,0.2
	c-0.15,0.26-0.06,0.6,0.2,0.76l1.77,1.03l-1.83,1.37c-0.25,0.18-0.29,0.53-0.11,0.78c0.11,0.15,0.28,0.22,0.44,0.22
	c0.12,0,0.23-0.04,0.33-0.11l2.2-1.65l1.04,0.61l-1.69,1.27c-0.24,0.18-0.29,0.53-0.11,0.78c0.11,0.15,0.28,0.22,0.44,0.22
	c0.12,0,0.23-0.04,0.33-0.11l2.06-1.55l1.49,0.87v4.91l-1.49,0.87l-2.06-1.55c-0.24-0.18-0.59-0.13-0.78,0.11
	c-0.18,0.24-0.13,0.59,0.11,0.78l1.69,1.27l-1.04,0.61l-2.2-1.65c-0.24-0.18-0.59-0.13-0.78,0.11c-0.18,0.24-0.13,0.59,0.11,0.78
	l1.83,1.37l-1.77,1.03c-0.26,0.15-0.35,0.49-0.2,0.76c0.1,0.18,0.29,0.28,0.48,0.28c0.09,0,0.19-0.02,0.28-0.08l1.38-0.81v1.44
	c0,0.31,0.25,0.55,0.55,0.55s0.55-0.25,0.55-0.55v-2.08l1.11-0.65v1.62c0,0.31,0.25,0.55,0.55,0.55s0.55-0.25,0.55-0.55v-2.27
	l1.65-0.97l3.89,2.43v1.57l-1.91,0.96c-0.27,0.14-0.38,0.47-0.25,0.74c0.14,0.27,0.47,0.38,0.74,0.25l1.42-0.71v0.98l-1.91,0.95
	c-0.27,0.14-0.38,0.47-0.25,0.74c0.14,0.27,0.47,0.38,0.74,0.25l1.42-0.71v1.32c0,0.31,0.25,0.55,0.55,0.55
	c0.31,0,0.55-0.25,0.55-0.55v-1.32l1.42,0.71c0.08,0.04,0.16,0.06,0.25,0.06c0.2,0,0.4-0.11,0.5-0.31c0.14-0.27,0.03-0.61-0.25-0.74
	l-1.91-0.95v-0.98l1.42,0.71c0.08,0.04,0.16,0.06,0.25,0.06c0.2,0,0.4-0.11,0.5-0.31c0.14-0.27,0.03-0.61-0.25-0.74l-1.91-0.96V42.1
	l3.89-2.43l1.65,0.97v2.27c0,0.31,0.25,0.55,0.55,0.55c0.31,0,0.55-0.25,0.55-0.55v-1.62l1.11,0.65v2.08c0,0.31,0.25,0.55,0.55,0.55
	c0.31,0,0.55-0.25,0.55-0.55v-1.44l1.38,0.81c0.09,0.05,0.18,0.08,0.28,0.08c0.19,0,0.38-0.1,0.48-0.28c0.15-0.27,0.06-0.6-0.2-0.76
	l-1.77-1.03l1.83-1.37c0.25-0.18,0.29-0.53,0.11-0.78c-0.18-0.25-0.53-0.3-0.78-0.11l-2.2,1.65l-1.04-0.61l1.69-1.27
	c0.25-0.18,0.29-0.53,0.11-0.78c-0.18-0.25-0.53-0.29-0.78-0.11l-2.06,1.55L37.2,38.7v-4.91l1.49-0.87l2.06,1.55
	c0.1,0.08,0.22,0.11,0.33,0.11c0.17,0,0.34-0.08,0.44-0.22c0.18-0.25,0.13-0.59-0.11-0.78l-1.69-1.27l1.04-0.61l2.2,1.65
	c0.1,0.07,0.22,0.11,0.33,0.11c0.17,0,0.34-0.08,0.44-0.22c0.18-0.24,0.13-0.59-0.11-0.78l-1.83-1.37l1.77-1.03
	C43.85,29.92,43.94,29.58,43.78,29.31"
    />
  </svg>
)

export default DualCooling
