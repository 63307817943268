import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const Faq: React.FunctionComponent<Props> = ({ width = '22', height = '23', fillColor = '#2D2926', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 22 23" {...rest}>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M2.072 10.114c.926 2.248 3.117 3.716 5.548 3.716h.04c.254 0 .46.206.46.46s-.206.46-.46.46c-2.785.004-5.297-1.67-6.364-4.243-1.068-2.571-.48-5.533 1.49-7.502 1.968-1.969 4.93-2.557 7.502-1.49 2.571 1.068 4.246 3.58 4.242 6.365-.034.225-.227.392-.455.392s-.421-.167-.455-.392c.02-2.431-1.429-4.634-3.669-5.579-2.24-.944-4.83-.443-6.556 1.269s-2.249 4.296-1.323 6.544zM5.33 6.46c.016-1.251 1.039-2.256 2.29-2.25.587-.071 1.176.11 1.62.5.445.39.703.95.71 1.54-.037.826-.488 1.579-1.2 2l-.127.109C8.25 8.676 8 8.89 8 9.25v.46h-.92v-.46c.066-.704.468-1.333 1.08-1.69.5-.41.84-.69.84-1.26-.005-.349-.16-.679-.427-.905-.266-.226-.617-.326-.963-.274-.372 0-.728.15-.987.419-.258.268-.397.629-.383 1l-.91-.08zM8 10.62h-.91v.92H8v-.92zm3.29-.91h8.25c.47.01.917.21 1.237.555.32.345.488.805.463 1.275v7.79c.025.474-.145.938-.47 1.285-.324.347-.775.547-1.25.555H17l-1.26 1.65c-.09.114-.226.18-.37.18-.144-.003-.28-.069-.37-.18l-1.19-1.65h-2.52c-1.026-.022-1.868-.817-1.95-1.84v-7.79c.059-1.032.916-1.837 1.95-1.83zm9.09 9.62c.025.23-.047.46-.199.635-.152.175-.37.278-.601.285h-2.79c-.144 0-.28.066-.37.18l-1 1.35-1-1.35c-.085-.113-.218-.18-.36-.18h-2.77c-.522.002-.958-.4-1-.92v-7.79c.042-.52.478-.922 1-.92h8.25c.231.007.45.11.601.285.152.175.224.405.199.635l.04 7.79zM13 14.88l1.96 1.93 3.32-4.18.72.57-3.67 4.63c-.082.1-.201.161-.33.17-.142 0-.275-.067-.36-.18l-2.29-2.29.65-.65z"
      clipRule="evenodd"
    />
  </svg>
)

export default Faq
