import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const TripleCooling: React.FunctionComponent<Props> = ({
  width = '50',
  height = '50',
  fillColor = '#2D2926',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 50 50" {...rest}>
    <path
      fill={fillColor}
      fillRule="evenodd"
      clipRule="evenodd"
      x="0px"
      y="0px"
      d="M13.99,35.92l-1.13-0.56l1.13-0.56c0.25-0.13,0.35-0.43,0.23-0.69c-0.13-0.25-0.43-0.35-0.69-0.23l-1.3,0.65v-1.22
	c0-0.28-0.23-0.51-0.51-0.51s-0.51,0.23-0.51,0.51v1.22l-1.3-0.65c-0.25-0.13-0.56-0.02-0.69,0.23c-0.13,0.25-0.02,0.56,0.23,0.69
	l1.13,0.56l-1.13,0.56C9.2,36.04,9.1,36.35,9.22,36.6c0.09,0.18,0.27,0.28,0.46,0.28c0.08,0,0.15-0.02,0.23-0.05l1.3-0.65v1.22
	c0,0.28,0.23,0.51,0.51,0.51s0.51-0.23,0.51-0.51v-1.22l1.3,0.65c0.07,0.04,0.15,0.05,0.23,0.05c0.19,0,0.37-0.1,0.46-0.28
	C14.34,36.35,14.24,36.04,13.99,35.92 M15.29,33.08v4.54l-3.57,2.23l-3.57-2.23v-4.54l3.57-2.23L15.29,33.08z M12.23,27.38v-0.9
	l1.76-0.88c0.25-0.13,0.35-0.43,0.23-0.69c-0.13-0.25-0.43-0.35-0.69-0.23l-1.3,0.65v-1.22c0-0.28-0.23-0.51-0.51-0.51
	s-0.51,0.23-0.51,0.51v1.22l-1.3-0.65c-0.25-0.13-0.56-0.02-0.69,0.23C9.1,25.17,9.2,25.48,9.45,25.6l1.76,0.88v0.9l-1.3-0.65
	c-0.25-0.13-0.56-0.02-0.69,0.23c-0.13,0.25-0.02,0.56,0.23,0.69l1.76,0.88v1.44l-3.58,2.24L6.1,31.32v-2.09
	c0-0.28-0.23-0.51-0.51-0.51c-0.28,0-0.51,0.23-0.51,0.51v1.49l-1.02-0.59v-1.92c0-0.28-0.23-0.51-0.51-0.51
	c-0.28,0-0.51,0.23-0.51,0.51v1.32l-1.27-0.74c-0.24-0.14-0.56-0.06-0.7,0.18c-0.14,0.24-0.06,0.56,0.18,0.7l1.63,0.95L1.2,31.88
	c-0.23,0.17-0.27,0.49-0.1,0.71c0.1,0.13,0.25,0.2,0.41,0.2c0.11,0,0.21-0.03,0.31-0.1l2.03-1.52l0.96,0.56L3.25,32.9
	c-0.23,0.17-0.27,0.49-0.1,0.71c0.1,0.13,0.25,0.2,0.41,0.2c0.11,0,0.21-0.03,0.31-0.1l1.9-1.42l1.37,0.8v4.52l-1.37,0.8l-1.9-1.42
	c-0.22-0.17-0.54-0.12-0.71,0.1c-0.17,0.23-0.12,0.55,0.1,0.71l1.55,1.17l-0.96,0.56l-2.03-1.52c-0.23-0.17-0.54-0.12-0.71,0.1
	c-0.17,0.23-0.12,0.55,0.1,0.71l1.68,1.26l-1.63,0.95c-0.24,0.14-0.33,0.45-0.18,0.7c0.1,0.16,0.27,0.25,0.44,0.25
	c0.09,0,0.18-0.02,0.26-0.07l1.27-0.74v1.32c0,0.28,0.23,0.51,0.51,0.51c0.28,0,0.51-0.23,0.51-0.51v-1.92l1.02-0.6v1.49
	c0,0.28,0.23,0.51,0.51,0.51c0.28,0,0.51-0.23,0.51-0.51v-2.09l1.52-0.89l3.58,2.24v1.44l-1.76,0.88C9.2,43.19,9.1,43.5,9.22,43.75
	C9.35,44,9.65,44.1,9.91,43.98l1.3-0.65v0.9L9.45,45.1c-0.25,0.13-0.35,0.43-0.23,0.69c0.13,0.25,0.43,0.35,0.69,0.23l1.3-0.65v1.22
	c0,0.28,0.23,0.51,0.51,0.51s0.51-0.23,0.51-0.51v-1.22l1.3,0.65c0.07,0.04,0.15,0.05,0.23,0.05c0.19,0,0.37-0.1,0.46-0.28
	c0.13-0.25,0.02-0.56-0.23-0.69l-1.76-0.88v-0.9l1.3,0.65c0.07,0.04,0.15,0.05,0.23,0.05c0.19,0,0.37-0.1,0.46-0.28
	c0.13-0.25,0.02-0.56-0.23-0.68l-1.76-0.88v-1.44l3.58-2.24l1.52,0.89v2.09c0,0.28,0.23,0.51,0.51,0.51s0.51-0.23,0.51-0.51v-1.49
	l1.02,0.6v1.92c0,0.28,0.23,0.51,0.51,0.51c0.28,0,0.51-0.23,0.51-0.51v-1.32l1.27,0.74c0.08,0.05,0.17,0.07,0.26,0.07
	c0.18,0,0.35-0.09,0.44-0.25c0.14-0.24,0.06-0.56-0.18-0.7l-1.63-0.95l1.68-1.26c0.23-0.17,0.27-0.49,0.1-0.71
	c-0.17-0.23-0.49-0.27-0.71-0.1l-2.03,1.52l-0.96-0.56l1.55-1.17c0.23-0.17,0.27-0.49,0.1-0.71c-0.17-0.23-0.49-0.27-0.71-0.1
	l-1.9,1.42l-1.37-0.8V33.1l1.37-0.8l1.9,1.42c0.09,0.07,0.2,0.1,0.31,0.1c0.16,0,0.31-0.07,0.41-0.2c0.17-0.23,0.12-0.55-0.1-0.71
	l-1.55-1.17l0.96-0.56l2.03,1.52c0.09,0.07,0.2,0.1,0.31,0.1c0.16,0,0.31-0.07,0.41-0.2c0.17-0.23,0.12-0.55-0.1-0.71l-1.68-1.26
	l1.63-0.95c0.24-0.14,0.33-0.46,0.18-0.7c-0.14-0.24-0.45-0.32-0.7-0.18l-1.27,0.74v-1.32c0-0.28-0.23-0.51-0.51-0.51
	c-0.28,0-0.51,0.23-0.51,0.51v1.92l-1.02,0.59v-1.49c0-0.28-0.23-0.51-0.51-0.51s-0.51,0.23-0.51,0.51v2.09l-1.52,0.89l-3.58-2.24
	v-1.44l1.76-0.88c0.25-0.13,0.35-0.43,0.23-0.69c-0.13-0.25-0.43-0.35-0.69-0.23L12.23,27.38z M25,17.2c0.28,0,0.51-0.23,0.51-0.51
	v-1.22l1.3,0.65c0.07,0.04,0.15,0.05,0.23,0.05c0.19,0,0.37-0.1,0.46-0.28c0.13-0.25,0.02-0.56-0.23-0.69l-1.13-0.56l1.13-0.56
	c0.25-0.13,0.35-0.43,0.23-0.69c-0.13-0.25-0.43-0.35-0.69-0.23l-1.3,0.65V12.6c0-0.28-0.23-0.51-0.51-0.51
	c-0.28,0-0.51,0.23-0.51,0.51v1.22l-1.3-0.65c-0.25-0.12-0.56-0.02-0.69,0.23c-0.13,0.25-0.02,0.56,0.23,0.69l1.13,0.56l-1.13,0.56
	c-0.25,0.13-0.35,0.43-0.23,0.69c0.09,0.18,0.27,0.28,0.46,0.28c0.08,0,0.16-0.02,0.23-0.05l1.3-0.65v1.22
	C24.49,16.97,24.72,17.2,25,17.2 M28.57,16.92L25,19.15l-3.57-2.23v-4.54L25,10.14l3.57,2.23V16.92z M35.47,20.33l-1.63-0.95
	l1.68-1.26c0.23-0.17,0.27-0.49,0.1-0.71c-0.17-0.23-0.49-0.27-0.71-0.1l-2.03,1.52l-0.96-0.56l1.55-1.17
	c0.23-0.17,0.27-0.49,0.1-0.71c-0.17-0.23-0.49-0.27-0.71-0.1l-1.9,1.42l-1.37-0.8v-4.52l1.37-0.8l1.9,1.42
	c0.09,0.07,0.2,0.1,0.31,0.1c0.16,0,0.31-0.07,0.41-0.2c0.17-0.23,0.12-0.55-0.1-0.71l-1.55-1.16l0.96-0.56l2.03,1.52
	c0.09,0.07,0.2,0.1,0.31,0.1c0.16,0,0.31-0.07,0.41-0.2c0.17-0.23,0.12-0.55-0.1-0.71l-1.68-1.26l1.63-0.95
	c0.24-0.14,0.33-0.45,0.18-0.7c-0.14-0.24-0.45-0.33-0.7-0.18l-1.27,0.74V7.5c0-0.28-0.23-0.51-0.51-0.51
	c-0.28,0-0.51,0.23-0.51,0.51v1.92l-1.02,0.59V8.52c0-0.28-0.23-0.51-0.51-0.51s-0.51,0.23-0.51,0.51v2.09l-1.52,0.89l-3.58-2.24
	V7.82l1.76-0.88c0.25-0.13,0.35-0.43,0.23-0.69C27.37,6,27.07,5.9,26.81,6.02l-1.3,0.65v-0.9l1.76-0.88
	c0.25-0.13,0.35-0.43,0.23-0.69c-0.13-0.25-0.43-0.35-0.69-0.23l-1.3,0.65V3.42c0-0.28-0.23-0.51-0.51-0.51
	c-0.28,0-0.51,0.23-0.51,0.51v1.22l-1.3-0.65c-0.25-0.13-0.56-0.02-0.69,0.23c-0.13,0.25-0.02,0.56,0.23,0.69l1.76,0.88v0.9
	l-1.3-0.65C22.94,5.9,22.63,6,22.5,6.25c-0.13,0.25-0.02,0.56,0.23,0.69l1.76,0.88v1.44l-3.58,2.24l-1.52-0.89V8.52
	c0-0.28-0.23-0.51-0.51-0.51c-0.28,0-0.51,0.23-0.51,0.51v1.49l-1.02-0.59V7.5c0-0.28-0.23-0.51-0.51-0.51s-0.51,0.23-0.51,0.51
	v1.32l-1.27-0.74c-0.24-0.14-0.56-0.06-0.7,0.18c-0.14,0.24-0.06,0.56,0.18,0.7l1.63,0.95l-1.68,1.26c-0.23,0.17-0.27,0.49-0.1,0.71
	c0.1,0.13,0.25,0.2,0.41,0.2c0.11,0,0.21-0.03,0.31-0.1l2.03-1.52l0.96,0.56l-1.55,1.16c-0.23,0.17-0.27,0.49-0.1,0.71
	c0.1,0.13,0.25,0.2,0.41,0.2c0.11,0,0.21-0.03,0.31-0.1l1.9-1.42l1.37,0.8v4.52l-1.37,0.8l-1.9-1.42c-0.22-0.17-0.54-0.12-0.71,0.1
	c-0.17,0.23-0.12,0.55,0.1,0.71l1.55,1.17l-0.96,0.56L15.1,17.3c-0.23-0.17-0.54-0.12-0.71,0.1c-0.17,0.23-0.12,0.55,0.1,0.71
	l1.68,1.26l-1.63,0.95c-0.24,0.14-0.33,0.46-0.18,0.7c0.1,0.16,0.27,0.25,0.44,0.25c0.09,0,0.18-0.02,0.26-0.07l1.27-0.74v1.32
	c0,0.28,0.23,0.51,0.51,0.51s0.51-0.23,0.51-0.51v-1.92l1.02-0.59v1.49c0,0.28,0.23,0.51,0.51,0.51c0.28,0,0.51-0.23,0.51-0.51
	v-2.09l1.52-0.89l3.58,2.24v1.44l-1.76,0.88c-0.25,0.13-0.35,0.43-0.23,0.69c0.13,0.25,0.43,0.35,0.69,0.23l1.3-0.65v0.9l-1.76,0.88
	c-0.25,0.13-0.35,0.43-0.23,0.69c0.13,0.25,0.43,0.35,0.69,0.23l1.3-0.65v1.22c0,0.28,0.23,0.51,0.51,0.51
	c0.28,0,0.51-0.23,0.51-0.51v-1.22l1.3,0.65c0.07,0.04,0.15,0.05,0.23,0.05c0.19,0,0.37-0.1,0.46-0.28
	c0.13-0.25,0.02-0.56-0.23-0.69l-1.76-0.88v-0.9l1.3,0.65c0.07,0.04,0.15,0.05,0.23,0.05c0.19,0,0.37-0.1,0.46-0.28
	c0.13-0.25,0.02-0.56-0.23-0.69l-1.76-0.88v-1.44l3.58-2.24l1.52,0.89v2.09c0,0.28,0.23,0.51,0.51,0.51s0.51-0.23,0.51-0.51v-1.49
	l1.02,0.59v1.92c0,0.28,0.23,0.51,0.51,0.51c0.28,0,0.51-0.23,0.51-0.51v-1.32l1.27,0.74c0.08,0.05,0.17,0.07,0.26,0.07
	c0.18,0,0.35-0.09,0.44-0.25C35.79,20.79,35.71,20.47,35.47,20.33 M38.28,37.91c0.28,0,0.51-0.23,0.51-0.51v-1.22l1.3,0.65
	c0.07,0.04,0.15,0.05,0.23,0.05c0.19,0,0.37-0.1,0.46-0.28c0.13-0.25,0.02-0.56-0.23-0.69l-1.13-0.56l1.13-0.56
	c0.25-0.13,0.35-0.43,0.23-0.69c-0.13-0.25-0.43-0.35-0.69-0.23l-1.3,0.65v-1.22c0-0.28-0.23-0.51-0.51-0.51
	c-0.28,0-0.51,0.23-0.51,0.51v1.22l-1.3-0.65c-0.25-0.13-0.56-0.02-0.69,0.23c-0.13,0.25-0.02,0.56,0.23,0.69l1.13,0.56l-1.13,0.56
	c-0.25,0.13-0.35,0.43-0.23,0.69c0.09,0.18,0.27,0.28,0.46,0.28c0.08,0,0.16-0.02,0.23-0.05l1.3-0.65v1.22
	C37.77,37.68,38,37.91,38.28,37.91 M41.85,37.62l-3.57,2.23l-3.57-2.23v-4.54l3.57-2.23l3.57,2.23V37.62z M48.93,28.97
	c-0.14-0.24-0.45-0.32-0.7-0.18l-1.27,0.74v-1.32c0-0.28-0.23-0.51-0.51-0.51c-0.28,0-0.51,0.23-0.51,0.51v1.92l-1.02,0.59v-1.49
	c0-0.28-0.23-0.51-0.51-0.51s-0.51,0.23-0.51,0.51v2.09l-1.52,0.89l-3.58-2.24v-1.44l1.76-0.88c0.25-0.13,0.35-0.43,0.23-0.69
	c-0.13-0.25-0.43-0.35-0.69-0.23l-1.3,0.65v-0.9l1.76-0.88c0.25-0.13,0.35-0.43,0.23-0.69c-0.13-0.25-0.43-0.35-0.69-0.23l-1.3,0.65
	v-1.22c0-0.28-0.23-0.51-0.51-0.51c-0.28,0-0.51,0.23-0.51,0.51v1.22l-1.3-0.65c-0.25-0.13-0.56-0.02-0.69,0.23
	c-0.13,0.25-0.02,0.56,0.23,0.69l1.76,0.88v0.9l-1.3-0.65c-0.25-0.13-0.56-0.02-0.69,0.23c-0.13,0.25-0.02,0.56,0.23,0.69l1.76,0.88
	v1.44l-3.58,2.24l-1.52-0.89v-2.09c0-0.28-0.23-0.51-0.51-0.51s-0.51,0.23-0.51,0.51v1.49l-1.02-0.59v-1.92
	c0-0.28-0.23-0.51-0.51-0.51s-0.51,0.23-0.51,0.51v1.32l-1.27-0.74c-0.24-0.14-0.56-0.06-0.7,0.18c-0.14,0.24-0.06,0.56,0.18,0.7
	l1.63,0.95l-1.68,1.26c-0.23,0.17-0.27,0.49-0.1,0.71c0.1,0.13,0.25,0.2,0.41,0.2c0.11,0,0.21-0.03,0.31-0.1l2.03-1.52l0.96,0.56
	l-1.55,1.17c-0.23,0.17-0.27,0.49-0.1,0.71c0.1,0.13,0.25,0.2,0.41,0.2c0.11,0,0.21-0.03,0.31-0.1l1.9-1.42l1.37,0.8v4.52l-1.37,0.8
	l-1.9-1.42c-0.22-0.17-0.54-0.12-0.71,0.1c-0.17,0.23-0.12,0.55,0.1,0.71l1.55,1.17l-0.96,0.56l-2.03-1.52
	c-0.23-0.17-0.54-0.12-0.71,0.1c-0.17,0.23-0.12,0.55,0.1,0.71l1.68,1.26l-1.63,0.95c-0.24,0.14-0.33,0.45-0.18,0.7
	c0.1,0.16,0.27,0.25,0.44,0.25c0.09,0,0.18-0.02,0.26-0.07l1.27-0.74v1.32c0,0.28,0.23,0.51,0.51,0.51s0.51-0.23,0.51-0.51v-1.92
	l1.02-0.6v1.49c0,0.28,0.23,0.51,0.51,0.51s0.51-0.23,0.51-0.51v-2.09l1.52-0.89l3.58,2.24v1.44l-1.76,0.88
	c-0.25,0.13-0.35,0.43-0.23,0.68c0.13,0.25,0.43,0.35,0.69,0.23l1.3-0.65v0.9l-1.76,0.88c-0.25,0.13-0.35,0.43-0.23,0.69
	c0.13,0.25,0.43,0.35,0.69,0.23l1.3-0.65v1.22c0,0.28,0.23,0.51,0.51,0.51c0.28,0,0.51-0.23,0.51-0.51v-1.22l1.3,0.65
	c0.07,0.04,0.15,0.05,0.23,0.05c0.19,0,0.37-0.1,0.46-0.28c0.13-0.25,0.02-0.56-0.23-0.69l-1.76-0.88v-0.9l1.3,0.65
	c0.07,0.04,0.15,0.05,0.23,0.05c0.19,0,0.37-0.1,0.46-0.28c0.13-0.25,0.02-0.56-0.23-0.68l-1.76-0.88v-1.44l3.58-2.24l1.52,0.89
	v2.09c0,0.28,0.23,0.51,0.51,0.51s0.51-0.23,0.51-0.51v-1.49l1.02,0.6v1.92c0,0.28,0.23,0.51,0.51,0.51c0.28,0,0.51-0.23,0.51-0.51
	v-1.32l1.27,0.74c0.08,0.05,0.17,0.07,0.26,0.07c0.18,0,0.35-0.09,0.44-0.25c0.14-0.24,0.06-0.56-0.18-0.7l-1.63-0.95l1.68-1.26
	c0.23-0.17,0.27-0.49,0.1-0.71c-0.17-0.23-0.49-0.27-0.71-0.1l-2.03,1.52l-0.96-0.56l1.55-1.17c0.23-0.17,0.27-0.49,0.1-0.71
	c-0.17-0.23-0.49-0.27-0.71-0.1l-1.9,1.42l-1.37-0.8V33.1l1.37-0.8l1.9,1.42c0.09,0.07,0.2,0.1,0.31,0.1c0.16,0,0.31-0.07,0.41-0.2
	c0.17-0.23,0.12-0.55-0.1-0.71l-1.55-1.17l0.96-0.56l2.03,1.52c0.09,0.07,0.2,0.1,0.31,0.1c0.16,0,0.31-0.07,0.41-0.2
	c0.17-0.23,0.12-0.55-0.1-0.71l-1.68-1.26l1.63-0.95C48.99,29.53,49.07,29.21,48.93,28.97"
    />
  </svg>
)

export default TripleCooling
