import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const Relief: React.FunctionComponent<Props> = ({ width = '24', height = '16', fillColor = '#2D2926', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 24 16" {...rest}>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M23.52.84h-1.29c-1.076-.028-2.138.242-3.07.78l-.42.22c-.2.109-.418.261-.656.428-.684.478-1.534 1.072-2.564 1.072-1.027 0-1.88-.59-2.57-1.068-.243-.168-.466-.323-.67-.432l-.42-.22c-.911-.526-1.948-.796-3-.78H.48C.35.843.226.897.136.99c-.09.094-.139.22-.136.35v12.32c-.011.813.637 1.483 1.45 1.5h21.1c.813-.017 1.461-.687 1.45-1.5V1.34c.003-.13-.046-.256-.136-.35-.09-.093-.214-.147-.344-.15zM23 13.66c.003.13-.046.256-.136.35-.09.093-.214.147-.344.15H1.45c-.13-.003-.254-.057-.344-.15-.09-.094-.139-.22-.136-.35V1.84h7.84c.913-.027 1.814.205 2.6.67l.4.21c.19.104.404.248.64.406.778.524 1.803 1.214 3.07 1.214 1.265 0 2.282-.687 3.058-1.21.236-.16.45-.305.642-.41l.39-.21c.794-.464 1.701-.696 2.62-.67H23v11.82zM6.31 6.48c.143.237.45.317.69.18.222-.141.293-.433.16-.66L5.59 3.37c-.091-.15-.254-.241-.43-.24-.178 0-.344.09-.44.24L3.15 6c-.122.229-.053.513.16.66.24.137.547.057.69-.18l.66-1.08v7.25c0 .276.224.5.5.5s.5-.224.5-.5V5.4l.65 1.08zm7.52 1.43c-.235.138-.538.063-.68-.17l-.65-1.09v6c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-6l-.65 1.09c-.144.237-.452.313-.69.17-.237-.144-.314-.453-.17-.69l1.57-2.63c.1-.152.269-.242.45-.24.175 0 .337.091.43.24L14 7.22c.14.238.064.544-.17.69zm5.91.33c.146.234.452.31.69.17.234-.146.31-.452.17-.69l-1.57-2.63c-.091-.15-.255-.241-.43-.24-.179-.003-.346.088-.44.24l-1.57 2.63c-.072.113-.095.251-.063.381s.116.242.233.309c.239.134.54.06.69-.17l.64-1.09v5.5c0 .276.224.5.5.5s.5-.224.5-.5v-5.5l.65 1.09z"
      clipRule="evenodd"
    />
  </svg>
)

export default Relief
