import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  variant?: 'stencil' | 'red'
  [x: string]: unknown // for the rest property
}

const Instagram: React.FunctionComponent<Props> = ({
  width,
  height,
  fillColor = '#2D2926',
  variant = 'stencil',
  ...rest
}) => {
  switch (variant) {
    case 'red':
      width = width ?? '35'
      height = height ?? '35'
      return (
        <svg width={width} height={height} viewBox="0 0 35 35" fill="none" {...rest}>
          <rect width={width} height={height} rx="17.5" fill="#D63426" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.1348 8H11.8652C9.73448 8 8 9.7333 8 11.8652V24.1348C8 26.2667 9.73448 28 11.8652 28H24.1348C26.2655 28 28 26.2667 28 24.1348V11.8652C28 9.7333 26.2655 8 24.1348 8ZM25.2437 10.306L25.6858 10.3036V13.6938L22.3062 13.7044L22.2944 10.3142L25.2437 10.306ZM15.146 15.9468C15.7857 15.0602 16.8253 14.4793 18 14.4793C19.1747 14.4793 20.2143 15.0602 20.854 15.9468C21.2714 16.5254 21.5207 17.2333 21.5207 18C21.5207 19.9403 19.9414 21.5195 18 21.5195C16.0597 21.5195 14.4805 19.9403 14.4805 18C14.4805 17.2333 14.7298 16.5254 15.146 15.9468ZM26.0515 24.1348C26.0515 25.1919 25.1919 26.0515 24.1348 26.0515H11.8652C10.8081 26.0515 9.94849 25.1919 9.94849 24.1348V15.9468H12.9353C12.6766 16.5818 12.532 17.2745 12.532 18C12.532 21.0151 14.9849 23.4692 18 23.4692C21.0162 23.4692 23.4692 21.0151 23.4692 18C23.4692 17.2745 23.3222 16.5818 23.0659 15.9468H26.0515V24.1348Z"
            fill="white"
          />
        </svg>
      )
    default:
      width = width ?? '30'
      height = height ?? '30'
      return (
        <svg width={width} height={height} viewBox="0 0 24 24" {...rest}>
          <path
            fill={fillColor}
            fillRule="evenodd"
            d="M3.5 0h17C22.433 0 24 1.567 24 3.5v17c0 1.933-1.567 3.5-3.5 3.5h-17C1.567 24 0 22.433 0 20.5v-17C0 1.567 1.567 0 3.5 0zM2 1.51v3c0 .276.224.5.5.5s.5-.224.5-.5V1.05c.166-.015.334-.015.5 0H4V4.5c0 .276.224.5.5.5s.5-.224.5-.5V1h1v3.5c0 .276.224.5.5.5s.5-.224.5-.5V1h13.5C21.88 1 23 2.12 23 3.5V7h-7.64c-2.03-1.372-4.69-1.372-6.72 0H1V3.5c.002-.784.372-1.521 1-1.99zM20.5 6h-3c-.276 0-.5-.224-.5-.5v-3c0-.276.224-.5.5-.5h3c.276 0 .5.224.5.5v3c0 .276-.224.5-.5.5zM18 3h2v2h-2V3zm-3 9c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3zm-1 0c0-1.105-.895-2-2-2s-2 .895-2 2 .895 2 2 2 2-.895 2-2zM3.5 23C2.12 23 1 21.88 1 20.5V8h6.54c-2.162 2.402-2.032 6.085.292 8.33 2.325 2.245 6.01 2.245 8.335 0 2.325-2.245 2.455-5.928.293-8.33H23v12.5c0 1.38-1.12 2.5-2.5 2.5h-17zM17 12c0-2.761-2.239-5-5-5s-5 2.239-5 5 2.239 5 5 5 5-2.239 5-5z"
            clipRule="evenodd"
          />
        </svg>
      )
  }
}

export default Instagram
