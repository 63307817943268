import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const Firm: React.FunctionComponent<Props> = ({ width = '26', height = '33', fillColor = '#2D2926', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 26 33" {...rest}>
    <path
      d="M3.25003 25.8753V28.0098H6.41288V29.187H3.25003V32.3498H1.99847V24.6238H6.48727V25.801H3.25003V25.8753Z"
      fill={fillColor}
    />
    <path d="M8.9904 24.6238V32.3498H7.73884V24.6238H8.9904Z" fill={fillColor} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7878 29.5524H13.2593L14.9539 32.353H16.351L14.5852 29.3357C15.4681 28.967 16.1311 28.0842 16.1311 27.1269C16.1311 25.7266 15.025 24.6238 13.628 24.6238H10.5363V32.3498H11.7878V29.5524ZM13.628 28.4496H11.7878V25.7977H13.628C14.2909 25.7977 14.8051 26.3863 14.8051 27.1237C14.8051 27.861 14.2909 28.4496 13.628 28.4496Z"
      fill={fillColor}
    />
    <path
      d="M24.6623 32.3498H23.4108V26.8294L21.0564 30.7296H20.9109L18.5566 26.8294V32.3466H17.305V24.6238H18.7021L21.0564 28.4496L23.4108 24.6238H24.7367V32.3498H24.6623Z"
      fill={fillColor}
    />
    <path
      d="M25.2898 20.523H1.44872C1.15442 20.523 0.934509 20.7429 0.934509 21.0372C0.934509 21.3315 1.15442 21.5514 1.44872 21.5514H25.2898C25.5841 21.5514 25.804 21.3315 25.804 21.0372C25.804 20.8173 25.5841 20.523 25.2898 20.523Z"
      fill={fillColor}
    />
    <path
      d="M25.2898 17.2114H1.44872C1.15442 17.2114 0.934509 17.4313 0.934509 17.7256C0.934509 18.0199 1.15442 18.2398 1.44872 18.2398H25.2898C25.5841 18.2398 25.804 18.0199 25.804 17.7256C25.804 17.4313 25.5841 17.2114 25.2898 17.2114Z"
      fill={fillColor}
    />
    <path
      d="M9.39464 14.194C9.68893 14.194 9.90884 13.9741 9.90884 13.6798V8.08823H16.9719C17.2662 8.08823 17.4861 7.86831 17.4861 7.57402C17.4861 7.27973 17.2662 7.05981 16.9719 7.05981H9.90884V1.68491H17.8548C18.1491 1.68491 18.369 1.46499 18.369 1.1707C18.369 0.876406 18.1491 0.656494 17.8548 0.656494H9.39464C9.10034 0.656494 8.88043 0.876406 8.88043 1.1707V13.6798C8.88043 13.8997 9.10034 14.194 9.39464 14.194Z"
      fill={fillColor}
    />
  </svg>
)

export default Firm
