import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const People: React.FunctionComponent<Props> = ({ width = '24', height = '22', fillColor = '#2D2926', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 24 22" {...rest}>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M12 7.7c-2.01 0-3.64-1.63-3.64-3.64C8.36 2.05 9.99.42 12 .42c2.01 0 3.64 1.63 3.64 3.64 0 .965-.383 1.891-1.066 2.574C13.89 7.316 12.965 7.7 12 7.7zm7.25 2.08c-1.052 0-2-.634-2.402-1.605-.402-.972-.18-2.09.564-2.834.743-.743 1.861-.966 2.833-.563.971.402 1.605 1.35 1.605 2.402-.006 1.434-1.166 2.594-2.6 2.6zm-.03.92c-.693.01-1.375.17-2 .47-1.276-1.556-3.183-2.457-5.195-2.457-2.012 0-3.919.901-5.195 2.457-.625-.3-1.308-.456-2-.46-1.274-.01-2.5.486-3.406 1.382C.518 12.987.005 14.206 0 15.48v5.57c.064.223.268.377.5.377.232 0 .436-.154.5-.377v-5.57c-.02-1.26.6-2.444 1.645-3.147 1.046-.702 2.376-.828 3.535-.333-.622 1.043-.95 2.235-.95 3.45v5.58c-.06.212.02.44.2.567.18.128.42.128.6 0s.26-.355.2-.567v-5.56c-.088-2.099.982-4.077 2.786-5.152 1.805-1.076 4.053-1.076 5.858 0 1.804 1.075 2.874 3.053 2.786 5.152v5.58c0 .287.233.52.52.52.287 0 .52-.233.52-.52v-5.58c.015-1.219-.303-2.419-.92-3.47 1.163-.501 2.501-.38 3.555.323 1.054.702 1.68 1.89 1.665 3.157v5.57c.064.223.268.377.5.377.232 0 .436-.154.5-.377v-5.57c-.006-2.638-2.142-4.775-4.78-4.78zm-1.53-3.52c0-.862.698-1.56 1.56-1.56.862 0 1.56.698 1.56 1.56 0 .861-.698 1.56-1.56 1.56-.862 0-1.56-.699-1.56-1.56zM4.78 9.78c-1.052 0-2-.634-2.402-1.605-.403-.972-.18-2.09.564-2.834.743-.743 1.861-.966 2.833-.563.972.402 1.605 1.35 1.605 2.402-.005 1.434-1.166 2.594-2.6 2.6zm-1.56-2.6c0-.862.698-1.56 1.56-1.56.862 0 1.56.698 1.56 1.56 0 .861-.698 1.56-1.56 1.56-.862 0-1.56-.699-1.56-1.56zm6.377-4.103C9.999 2.104 10.947 1.47 12 1.47c1.432 0 2.595 1.158 2.6 2.59.004 1.052-.627 2.004-1.598 2.41-.971.405-2.091.185-2.837-.558-.746-.743-.97-1.862-.568-2.835z"
      clipRule="evenodd"
    />
  </svg>
)

export default People
