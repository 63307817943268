import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const King: React.FunctionComponent<Props> = ({ width = '25', height = '24', fillColor = '#2D2926', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 25 24" {...rest}>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M.48 23.87h23c.145.011.287-.039.394-.137.106-.1.166-.238.166-.383V.65c0-.287-.233-.52-.52-.52h-23C.233.13 0 .363 0 .65v22.7c0 .272.209.499.48.52zM23 22.83H1V1.17h22v21.66zM21.39 22H2.61c-.138 0-.27-.055-.368-.152-.097-.098-.152-.23-.152-.368V9.19c0-.287.233-.52.52-.52h18.78c.287 0 .52.233.52.52v12.28c.003.14-.05.274-.149.374-.098.1-.231.156-.371.156zm-.52-1H3.13V9.71h17.74V21zM9.79 2.16H5.28c-.77 0-1.442.523-1.63 1.27l-.58 2.32c-.122.502-.008 1.033.31 1.44.317.407.804.643 1.32.64h5.09c.928 0 1.68-.752 1.68-1.68V3.84c0-.928-.752-1.68-1.68-1.68zm.64 3.99V3.84c0-.354-.287-.64-.64-.64H5.28c-.292 0-.547.197-.62.48L4.08 6c-.047.192-.003.395.12.55.122.152.306.24.5.24h5.09c.354 0 .64-.287.64-.64zm10.5-.4l-.58-2.32c-.188-.747-.86-1.27-1.63-1.27h-4.51c-.928 0-1.68.752-1.68 1.68v2.31c0 .928.752 1.68 1.68 1.68h5.09c.515.003 1.003-.233 1.32-.64.318-.407.432-.938.31-1.44zM19.3 6.79c.194 0 .378-.088.5-.24.123-.155.167-.358.12-.55l-.58-2.32c-.073-.283-.328-.48-.62-.48h-4.51c-.354 0-.64.286-.64.64v2.31c0 .353.287.64.64.64h5.09z"
      clipRule="evenodd"
    />
  </svg>
)

export default King
