import { Playground, Props } from 'docz';
import { Row } from "../../../../../documentation/components/helpers";
import Eurotop from "../../../../../src/Icons/Eurotop";
import Foam from "../../../../../src/Icons/Foam";
import GelMemoryFoam from "../../../../../src/Icons/GelMemoryFoam";
import Hybrid from "../../../../../src/Icons/Hybrid";
import Innerspring from "../../../../../src/Icons/Innerspring";
import Latex from "../../../../../src/Icons/Latex";
import MemoryFoam from "../../../../../src/Icons/MemoryFoam";
import Pillowtop from "../../../../../src/Icons/Pillowtop";
import PocketCoils from "../../../../../src/Icons/PocketCoils";
import * as React from 'react';
export default {
  Playground,
  Props,
  Row,
  Eurotop,
  Foam,
  GelMemoryFoam,
  Hybrid,
  Innerspring,
  Latex,
  MemoryFoam,
  Pillowtop,
  PocketCoils,
  React
};