import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const Glider: React.FunctionComponent<Props> = ({ width = '26', height = '25', fillColor = '#2D2926', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 26 25" {...rest}>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M14.16 13.68c-.005.263-.217.475-.48.48l-2.2.03.2 2.65h.75c.622.005 1.17.408 1.36 1l.35 1.12c.044.146.017.304-.074.426-.09.122-.234.194-.386.194h-2.91l1.75 1.75h1.16c.265 0 .48.215.48.48-.005.263-.217.475-.48.48h-.88V24h.88c.276 0 .5.224.5.5s-.224.5-.5.5H1.48c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h.88v-1.71h-.88c-.127 0-.25-.05-.34-.14-.09-.09-.14-.213-.14-.34-.003-.128.047-.252.138-.342.09-.091.214-.14.342-.138h1.16l1.88-1.88c-1.3-.363-2.232-1.504-2.33-2.85L1 1.52c-.015-.16.048-.316.17-.42.12-.097.279-.134.43-.1l1.23.33c.605.14 1.053.652 1.11 1.27l1 11.6 5.95-1h2.79c.265 0 .48.215.48.48zm10.7 8.47c-.09.09-.213.14-.34.14h-.88V24h.88c.276 0 .5.224.5.5s-.224.5-.5.5h-8.13c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h.87v-1.71h-.87c-.127 0-.25-.05-.34-.14-.09-.09-.14-.213-.14-.34V19.1c-.003-.128.047-.252.137-.343.091-.09.215-.14.343-.137h8.13c.128-.003.252.047.342.137.091.091.14.215.138.343v2.71c0 .127-.05.25-.14.34zM24 19.58h-7.13v1.75H24v-1.75zM22.68 24h-4.46v-1.71h4.46V24zM2 2.11l.59.15c.215.034.382.205.41.42l1 11.67-1 .17L2 2.11zm1.06 13.37l.09 1.05c.074 1.173 1.045 2.087 2.22 2.09h5.52l-.07-.86L6 17.69c-.97.03-1.796-.703-1.88-1.67l-.06-.71-1 .17zM11.84 24H3.32v-1.71h8.52V24zM4 21.29h7.16l-1.74-1.75H5.75L4 21.29zm8.42-3.54c.206 0 .389.133.45.33l.15.5h-1.21l-.06-.84.67.01zm-7.3-1.82c.046.452.425.797.88.8l4.68.07-.18-2.56-5.5.91.12.78z"
      clipRule="evenodd"
    />
  </svg>
)

export default Glider
