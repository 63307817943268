import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const Ruler: React.FunctionComponent<Props> = ({ width = '26', height = '25', fillColor = '#2D2926', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 26 25" {...rest}>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M18.08.21L1.14 17.15c-.09.09-.141.212-.141.34 0 .128.05.25.141.34l6.1 6.1c.09.09.212.141.34.141.128 0 .25-.05.34-.141L24.86 7c.09-.09.141-.212.141-.34 0-.128-.05-.25-.141-.34l-6.1-6.1c-.089-.092-.21-.144-.338-.146-.128-.002-.25.047-.342.136zm5.76 6.44L22.53 8l-1.7-1.69c-.185-.185-.485-.185-.67 0-.185.185-.185.485 0 .67l1.69 1.7-1.48 1.48-3.05-3c-.09-.09-.212-.141-.34-.141-.128 0-.25.05-.34.141-.183.19-.183.49 0 .68l3.05 3.05-1.48 1.48-1.69-1.69c-.09-.09-.212-.141-.34-.141-.128 0-.25.05-.34.141-.09.088-.14.21-.14.335 0 .126.05.247.14.335L17.53 13l-1.48 1.48L13 11.39c-.188-.188-.492-.188-.68 0-.188.188-.188.492 0 .68l3.05 3.05-1.48 1.48-1.69-1.69c-.09-.09-.212-.141-.34-.141-.128 0-.25.05-.34.141-.09.088-.14.21-.14.335 0 .126.05.247.14.335l1.69 1.7-1.48 1.48-3.05-3.05c-.121-.121-.299-.169-.464-.124-.166.044-.296.174-.34.34-.045.165.003.343.124.464l3.05 3.05-1.48 1.48-1.7-1.69c-.088-.09-.21-.14-.335-.14-.126 0-.247.05-.335.14-.09.09-.141.212-.141.34 0 .128.05.25.141.34l1.69 1.69-1.31 1.31-5.42-5.42L18.42 1.23l5.42 5.42z"
      clipRule="evenodd"
    />
  </svg>
)

export default Ruler
