import { Playground, Props } from 'docz';
import { Row } from "../../../../../documentation/components/helpers";
import PiggyBank from "../../../../../src/Icons/PiggyBank";
import Wallet from "../../../../../src/Icons/Wallet";
import FinanceTag from "../../../../../src/Icons/FinanceTag";
import * as React from 'react';
export default {
  Playground,
  Props,
  Row,
  PiggyBank,
  Wallet,
  FinanceTag,
  React
};