import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown
}

const CheckmarkOutline: React.FunctionComponent<Props> = ({
  width = '12',
  height = '10',
  fillColor = '#000000',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.51817 11.6509C2.19377 11.6509 1.86836 11.7523 1.59333 11.9605C0.934449 12.4614 0.814057 13.3951 1.32434 14.0414L7.31217 21.6354C7.59627 21.9954 8.04258 22.211 8.50701 22.211H8.51256C8.9775 22.2096 9.42481 21.9915 9.7074 21.628L22.682 4.97227C22.9268 4.65726 23.0326 4.26757 22.9792 3.87491C22.9258 3.48176 22.7197 3.13312 22.3989 2.89229C21.738 2.39627 20.7869 2.51991 20.2802 3.17071L8.49996 18.2944L3.71403 12.2255C3.41733 11.8487 2.96952 11.6509 2.51817 11.6509M8.51457 23.2001H8.50702C7.73329 23.2001 6.98928 22.8411 6.51527 22.2412L0.527437 14.6477C0.114882 14.125 -0.0644453 13.4767 0.020685 12.8219C0.105815 12.1662 0.444825 11.5831 0.97626 11.1781C2.07792 10.3463 3.66164 10.5436 4.51093 11.6202L8.49493 16.6723L19.4808 2.56887C20.3255 1.48635 21.9108 1.28013 23.0109 2.10698C23.5454 2.50755 23.8889 3.08961 23.9776 3.74387C24.0662 4.39862 23.8904 5.04844 23.4819 5.57264L10.5073 22.2288C10.0363 22.8346 9.29032 23.1971 8.51457 23.2001"
      fill={fillColor}
    />
  </svg>
)

export default CheckmarkOutline
