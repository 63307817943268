import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const Toddler: React.FunctionComponent<Props> = ({ width = '24', height = '14', fillColor = '#2D2926', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 24 22" {...rest}>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M21 3.18h1.75c.276 0 .5.224.5.5s-.224.5-.5.5h-3c-.201-.004-.381-.125-.46-.31-.082-.188-.038-.408.11-.55l2.14-2.17h-1.79c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h3c.202 0 .383.123.46.31.08.185.037.401-.11.54L21 3.18zM.76 11.75c0-5.562 4.509-10.07 10.07-10.07 5.557.01 10.059 4.513 10.07 10.07 0 5.561-4.508 10.07-10.07 10.07C5.269 21.82.76 17.31.76 11.75zm12.57-5.9c.81 2.74 2.97 4.19 6.42 4.31-.549-3.056-2.619-5.62-5.49-6.8-.198.867-.511 1.705-.93 2.49zm-2.5-3.17c.834-.012 1.666.096 2.47.32-.875 4.19-4.58 7.184-8.86 7.16-.824.001-1.644-.106-2.44-.32.9-4.162 4.572-7.14 8.83-7.16zm-9.08 9.07c.006 5.01 4.07 9.07 9.08 9.07 4.98-.022 9.013-4.05 9.04-9.03v-.58c-3.62-.09-6.09-1.53-7.21-4.21-1.904 2.667-4.993 4.233-8.27 4.19-.892-.004-1.78-.125-2.64-.36v.92zm11.17 4.89c-.24-.138-.547-.058-.69.18-.289.503-.825.813-1.405.813-.58 0-1.116-.31-1.405-.813-.143-.238-.45-.318-.69-.18-.116.065-.2.174-.234.302s-.014.264.054.378c.47.811 1.337 1.31 2.275 1.31s1.805-.499 2.275-1.31c.068-.114.088-.25.054-.378-.034-.128-.118-.237-.234-.302zm-3.266-4.698c.034.128.014.264-.054.378-.47.811-1.337 1.31-2.275 1.31s-1.804-.499-2.275-1.31c-.068-.114-.088-.25-.054-.378.034-.128.118-.237.234-.302.24-.138.547-.058.69.18.289.503.825.813 1.405.813.58 0 1.116-.31 1.405-.813.143-.238.45-.318.69-.18.116.065.2.174.234.302zm6.766-.302c-.24-.138-.547-.058-.69.18-.289.503-.825.813-1.405.813-.58 0-1.116-.31-1.405-.813-.143-.238-.45-.318-.69-.18-.116.065-.2.174-.234.302s-.014.264.054.378c.47.811 1.337 1.31 2.275 1.31s1.805-.499 2.275-1.31c.068-.114.088-.25.054-.378-.034-.128-.118-.237-.234-.302z"
      clipRule="evenodd"
    />
  </svg>
)

export default Toddler
