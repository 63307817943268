import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown
}

const SleepysBrand: React.FunctionComponent<Props> = ({ width = 146, height = 42, fillColor = '#0F206C', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 146 42" {...rest}>
    <g fill={fillColor} fillRule="nonzero">
      <path d="M121.21 2.152a7.578 7.578 0 0 0-1.673-.15l-2.228.038 1.994.908c2.703 1.231 4.133 4.273 3.402 7.233-.731 2.958-3.456 5.142-6.477 5.194l-2.228.038 1.994.908a7.388 7.388 0 0 0 3.122.679c3.525 0 6.787-2.44 7.66-5.981.483-1.953.178-3.95-.863-5.62-1.051-1.685-2.72-2.838-4.702-3.247zM10.272 29.35c-4.285 0-6.895-3.033-7.174-5.888l-.046-.464H.026l.041.552c.326 4.33 4.231 8.808 10.205 8.808 6.356 0 9.992-3.816 10.773-7.596l.005-.026c.798-5.095-2.168-8.684-8.352-10.103l-4.31-.97c-4.501-1.033-5.022-3.33-5.022-4.968 0-3.415 3.56-5.527 6.859-5.527 3.905 0 6.664 2.2 6.862 5.472l.03.482h3.028l-.023-.535C19.896 3.625 15.828.159 10.227.159 5.447.16.288 3.425.288 8.698c0 4.165 2.515 6.81 7.464 7.862l4.271 1.015c4.5 1.027 6.536 3.261 6.05 6.644-.524 2.555-3.136 5.13-7.801 5.13zM23.744.312h3.035v31.606h-3.035zM79.011 28.687c1.928 2.298 4.964 3.671 8.283 3.671 6.296 0 11.227-4.913 11.227-11.183 0-6.346-4.93-11.317-11.227-11.317-3.247 0-6.336 1.436-8.283 3.76v-3.319h-3.08v31.586h3.08V28.687zm0-7.724c.081-4.59 3.643-8.05 8.283-8.05 4.57 0 8.15 3.63 8.15 8.262 0 4.634-3.503 8.13-8.15 8.13-4.722-.002-8.283-3.59-8.283-8.342z" />
      <path d="M118.467 10.3h-3.306l-7.433 17.365-7.392-17.366h-3.254l8.973 21.194-4.347 10.392h3.114zM129.282 29.393c-3.489 0-4.563-1.94-5.066-3.401l-.12-.347h-2.827l.077.58c.556 4.216 4.433 6.133 7.802 6.133 4.596 0 7.805-2.64 7.805-6.422 0-2.206-.955-5.072-5.507-6.187l-2.978-.706c-2.451-.577-3.47-1.74-3.207-3.658.225-1.483 1.935-2.561 4.066-2.561 2.058 0 3.586 1.098 3.891 2.794l.077.425h3.014l-.067-.572c-.407-3.462-3.057-5.613-6.913-5.613-3.69 0-6.671 2.151-7.092 5.133-.595 5.587 3.998 6.644 5.508 6.992l3.021.706c2.096.494 3.16 1.588 3.16 3.25-.006 2.097-1.825 3.454-4.644 3.454zM39.884 32.01c4.27 0 8.07-2.338 9.92-6.1l.346-.703-3.307-.143-.158.26c-1.394 2.301-3.936 3.677-6.801 3.677-4.07 0-7.204-2.7-7.802-6.72l-.06-.683c-.024-3.243.72-5.643 2.212-7.133 1.288-1.29 3.189-1.943 5.647-1.943 4.068 0 6.457 2.035 7.28 6.219-4.668-.993-10.218-.436-13.467.683l-.32.11-.026.335c-.021.277-.028.552-.033.82-.002.113-.005.228-.01.341l-.02.64.635-.12c3.344-.63 9.867-.875 13.897.555l.084.03h2.561l.063-.44c.049-.344.049-.677.049-.997 0-6.586-4.399-11.186-10.695-11.186-6.352 0-10.962 4.703-10.962 11.186.004 6.551 4.614 11.312 10.967 11.312zM63.174 32.01c4.27 0 8.07-2.338 9.92-6.1l.346-.703-3.307-.143-.158.26c-1.394 2.301-3.936 3.677-6.801 3.677-4.07 0-7.204-2.7-7.802-6.72l-.06-.683c-.024-3.243.72-5.643 2.212-7.133 1.288-1.29 3.189-1.943 5.647-1.943 4.068 0 6.457 2.035 7.28 6.219-4.668-.993-10.218-.436-13.467.683l-.32.11-.026.335c-.021.277-.028.552-.033.82-.002.113-.005.228-.01.341l-.02.64.635-.12c3.344-.63 9.867-.875 13.897.555l.084.03h2.561l.063-.44c.049-.344.049-.677.049-.997 0-6.586-4.399-11.186-10.695-11.186-6.352 0-10.962 4.703-10.962 11.186.004 6.551 4.614 11.312 10.967 11.312z" />
      <g>
        <path d="M140.992 11.005v.49h-1.128v3.34h-.506v-3.34h-1.128v-.49zM145.067 11.005h.458l.453 3.83h-.497l-.34-2.88-1.158 2.458h-.441l-1.169-2.458-.34 2.88h-.494l.454-3.83h.458l1.311 2.813z" />
      </g>
    </g>
  </svg>
)

export default SleepysBrand
