import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const Medium: React.FunctionComponent<Props> = ({ width = '38', height = '30', fillColor = '#2D2926', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 38 30" {...rest}>
    <path
      d="M7.45964 29.1583H6.32975V24.1745L4.20427 27.6956H4.07289L1.94741 24.1745V29.1554H0.750366V22.1833H2.01164L4.13712 25.6373L6.2626 22.1833H7.45964V29.1583Z"
      fill={fillColor}
    />
    <path
      d="M13.1062 28.0956V29.1583H8.85522V22.1833H13.039V23.2461H9.9822V25.0387H12.7733V26.1686H9.9822V28.0284H13.1033V28.0956H13.1062Z"
      fill={fillColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8929 29.2255C18.8199 29.2255 20.2155 27.6314 20.2155 25.7044C20.2155 23.7103 18.8199 22.1833 16.8929 22.1833H14.1689V29.2255H16.8929ZM16.8258 23.3132C18.1542 23.3132 19.0856 24.3088 19.0856 25.7044C19.0856 27.0328 18.2214 28.0956 16.8929 28.0956H15.2317V23.3132H16.8258Z"
      fill={fillColor}
    />
    <path d="M22.4081 22.1833V29.1583H21.2782V22.1833H22.4081Z" fill={fillColor} />
    <path
      d="M23.7365 26.8343V22.1833H24.8664V26.7671C24.8664 27.5642 25.3306 28.1627 26.3934 28.1627C27.4561 28.1627 27.9203 27.5642 27.9203 26.7671V22.1833H29.0502V26.8343C29.0502 28.3613 27.9203 29.2926 26.3934 29.2926C24.7993 29.2897 23.7365 28.3613 23.7365 26.8343Z"
      fill={fillColor}
    />
    <path
      d="M37.0207 29.1583H35.8908V24.1745L33.7654 27.6956H33.6311L31.4384 24.1745V29.1554H30.3085V22.1833H31.5698L33.6953 25.6373L35.8208 22.1833H37.0178C37.0207 22.1833 37.0207 29.1583 37.0207 29.1583Z"
      fill={fillColor}
    />
    <path
      d="M13.7748 12.6508C14.0404 12.6508 14.239 12.4523 14.239 12.1866V2.42046L18.4899 8.66552C18.5571 8.79982 18.7556 8.86405 18.8899 8.86405C19.0242 8.86405 19.2228 8.7969 19.2899 8.66552L23.538 2.42046V12.1866C23.538 12.4523 23.7365 12.6508 24.0022 12.6508C24.2679 12.6508 24.4664 12.4523 24.4664 12.1866V0.893499C24.4664 0.694965 24.3321 0.493512 24.1336 0.42928C23.935 0.362129 23.7336 0.42928 23.6022 0.627814L18.9542 7.53563L14.239 0.627814C14.1047 0.42928 13.9061 0.362129 13.7076 0.42928C13.5091 0.496431 13.3748 0.694965 13.3748 0.893499V12.1866C13.3076 12.3851 13.5091 12.6508 13.7748 12.6508Z"
      fill={fillColor}
    />
    <path
      d="M29.6487 18.3645H8.12529C7.85961 18.3645 7.66107 18.563 7.66107 18.8287C7.66107 19.0944 7.85961 19.2929 8.12529 19.2929H29.6487C29.9144 19.2929 30.1129 19.0944 30.1129 18.8287C30.1158 18.563 29.9144 18.3645 29.6487 18.3645Z"
      fill={fillColor}
    />
    <path
      d="M29.6487 15.5733C29.1173 15.5733 28.8516 15.439 28.5188 15.2405C28.0546 14.9748 27.5232 14.642 26.3933 14.642C25.3306 14.642 24.7992 14.9077 24.335 15.1733C23.8708 15.3719 23.4708 15.5733 22.6737 15.5733C21.8767 15.5733 21.4767 15.3748 21.0125 15.1733C20.5482 14.9077 19.9497 14.642 18.9541 14.642C17.8914 14.642 17.36 14.9077 16.8958 15.1733C16.4316 15.3719 16.0316 15.5733 15.2345 15.5733C14.4375 15.5733 14.0375 15.3748 13.5733 15.1733C13.1091 14.9077 12.5105 14.642 11.5149 14.642C10.385 14.642 9.85368 14.9748 9.38946 15.2405C9.05662 15.439 8.79094 15.5733 8.25957 15.5733C7.99388 15.5733 7.79535 15.7719 7.79535 16.0376C7.79535 16.3032 7.99388 16.5018 8.25957 16.5018C9.05662 16.5018 9.45661 16.2361 9.85368 16.0376C10.2537 15.7719 10.6507 15.5733 11.5149 15.5733C12.312 15.5733 12.712 15.7719 13.1762 15.9733C13.6404 16.239 14.2389 16.5047 15.2345 16.5047C16.2973 16.5047 16.8286 16.239 17.2929 15.9733C17.7571 15.7748 18.1571 15.5733 18.9541 15.5733C19.7512 15.5733 20.1512 15.7719 20.6154 15.9733C21.0796 16.239 21.6781 16.5047 22.6737 16.5047C23.7365 16.5047 24.2678 16.239 24.732 15.9733C25.1963 15.7748 25.5962 15.5733 26.3933 15.5733C27.2575 15.5733 27.6546 15.7719 28.0546 16.0376C28.4546 16.2361 28.9188 16.5018 29.6487 16.5018C29.9144 16.5018 30.1129 16.3032 30.1129 16.0376C30.1158 15.839 29.9144 15.5733 29.6487 15.5733Z"
      fill={fillColor}
    />
  </svg>
)

export default Medium
