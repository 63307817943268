import { Playground, Props } from 'docz';
import { Row } from "../../../../../documentation/components/helpers";
import Calendar from "../../../../../src/Icons/Calendar";
import Free from "../../../../../src/Icons/Free";
import Value from "../../../../../src/Icons/Value";
import WarrantyCertificate from "../../../../../src/Icons/WarrantyCertificate";
import DeliveryTruck from "../../../../../src/Icons/DeliveryTruck";
import Mattresses from "../../../../../src/Icons/Mattresses";
import PaperMoney from "../../../../../src/Icons/PaperMoney";
import People from "../../../../../src/Icons/People";
import Zoom from "../../../../../src/Icons/Zoom";
import * as React from 'react';
export default {
  Playground,
  Props,
  Row,
  Calendar,
  Free,
  Value,
  WarrantyCertificate,
  DeliveryTruck,
  Mattresses,
  PaperMoney,
  People,
  Zoom,
  React
};