import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  variant?: 'stencil' | 'red'
  [x: string]: unknown // for the rest property
}

const Pinterest: React.FunctionComponent<Props> = ({
  width,
  height,
  fillColor = '#2D2926',
  variant = 'stencil',
  ...rest
}) => {
  switch (variant) {
    case 'red':
      width = width ?? '35'
      height = height ?? '35'
      return (
        <svg width={width} height={height} viewBox="0 0 35 35" fill="none" {...rest}>
          <rect width={width} height={height} rx="17.5" fill="#D63426" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 14.3292C8 16.8982 8.96694 19.1833 11.0394 20.035C11.3794 20.1745 11.6835 20.0399 11.7822 19.6608C11.8506 19.3991 12.0129 18.7378 12.0846 18.4621C12.1841 18.0879 12.1458 17.9566 11.871 17.6301C11.2742 16.9203 10.8919 16.0022 10.8919 14.7009C10.8919 10.9274 13.6973 7.5485 18.1985 7.5485C22.1837 7.5485 24.3728 9.99936 24.3728 13.2715C24.3728 17.5776 22.4788 21.2124 19.6685 21.2124C18.1154 21.2124 16.9536 19.9201 17.3262 18.3357C17.7721 16.4436 18.6355 14.4014 18.6355 13.0361C18.6355 11.8135 17.9833 10.7944 16.6348 10.7944C15.0482 10.7944 13.7723 12.4461 13.7723 14.659C13.7723 16.0695 14.246 17.0221 14.246 17.0221C14.246 17.0221 12.6219 23.948 12.3374 25.1607C11.7707 27.5763 12.2526 30.5367 12.2933 30.8354C12.317 31.0126 12.5428 31.0552 12.6456 30.9215C12.7923 30.7287 14.6813 28.3804 15.3254 26.0346C15.5064 25.3699 16.3682 21.9296 16.3682 21.9296C16.8843 22.9191 18.3909 23.7905 19.993 23.7905C24.7641 23.7905 28 19.4139 28 13.5554C28 9.12552 24.2717 5 18.6054 5C11.5555 5 8 10.0872 8 14.3292Z"
            fill="white"
          />
        </svg>
      )
    default:
      width = width ?? '30'
      height = height ?? '30'
      return (
        <svg width={width} height={height} viewBox="0 0 20 25" {...rest}>
          <path
            fill={fillColor}
            fillRule="evenodd"
            d="M5.15 23.85c.093.093.218.147.35.15.105.008.21-.016.3-.07.919-.53 1.678-1.297 2.2-2.22.37-.9 1.31-3.32 1.74-4.45.84.482 1.791.737 2.76.74C18 18 20 13.15 20 9c0-3.11-2-9-9.5-9C1.89 0 0 6.5 0 9c0 2.81 2.69 7 4.5 7 .23.01.436-.14.5-.36l.37-1.38L4 20.4c-.192 1.268.236 2.55 1.15 3.45zM9 7c-.671-.029-1.324.226-1.8.7-.474.476-.729 1.129-.7 1.8.061.624.23 1.233.5 1.8.03.101.03.209 0 .31l-2 9c-.108.797.108 1.604.6 2.24.601-.4 1.101-.934 1.46-1.56.51-1.13 2-4.93 2-5 .06-.151.191-.263.35-.3.157-.05.329-.011.45.1.758.58 1.685.9 2.64.91 6.19 0 6.5-6.66 6.5-8 0-3-1.79-8-8.5-8C2.71 1 1 6.77 1 9c.19 2.317 1.33 4.453 3.15 5.9l.79-2.75c-.637-.733-.973-1.68-.94-2.65 0-3.94 3.27-6 6.5-6 2.897-.13 5.354 2.104 5.5 5 0 2.09-1.89 6.5-4 6.5-.672.023-1.324-.234-1.8-.71-.474-.472-.727-1.122-.7-1.79-.002-.133.053-.26.15-.35.79-1.062 1.26-2.329 1.35-3.65.04-.36-.069-.72-.3-1-.483-.377-1.09-.555-1.7-.5zm1.51 5.68c.014.383.184.745.47 1 .283.24.65.356 1.02.32 1.12 0 3-3.44 3-5.5-.145-2.344-2.155-4.131-4.5-4-2.65 0-5.5 1.56-5.5 5-.055.784.237 1.552.8 2.1.054.04.098.091.13.15l.07-.21c-.269-.636-.43-1.311-.48-2-.06-.946.284-1.872.948-2.548C7.133 6.316 8.053 5.955 9 6c.872-.072 1.737.2 2.41.76.418.464.631 1.077.59 1.7-.08 1.52-.598 2.986-1.49 4.22z"
            clipRule="evenodd"
          />
        </svg>
      )
  }
}

export default Pinterest
