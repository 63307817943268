import { Playground, Props } from 'docz';
import { Row } from "../../../../../documentation/components/helpers";
import MattressFirmBrand from "../../../../../src/Icons/MattressFirmBrand";
import SleepDotComBrand from "../../../../../src/Icons/SleepDotComBrand";
import BeautyRestBrand from "../../../../../src/Icons/BeautyRestBrand";
import LullBrand from "../../../../../src/Icons/LullBrand";
import KingsdownSelectBrand from "../../../../../src/Icons/KingsdownSelectBrand";
import PurpleBrand from "../../../../../src/Icons/PurpleBrand";
import SealyBrand from "../../../../../src/Icons/SealyBrand";
import SertaBrand from "../../../../../src/Icons/SertaBrand";
import SleepysBrand from "../../../../../src/Icons/SleepysBrand";
import StearnsAndFosterBrand from "../../../../../src/Icons/StearnsAndFosterBrand";
import TempurPedicBrand from "../../../../../src/Icons/TempurPedicBrand";
import TuftAndNeedleBrand from "../../../../../src/Icons/TuftAndNeedleBrand";
import IntellibedBrand from "../../../../../src/Icons/IntellibedBrand";
import TuloBrand from "../../../../../src/Icons/TuloBrand";
import * as React from 'react';
export default {
  Playground,
  Props,
  Row,
  MattressFirmBrand,
  SleepDotComBrand,
  BeautyRestBrand,
  LullBrand,
  KingsdownSelectBrand,
  PurpleBrand,
  SealyBrand,
  SertaBrand,
  SleepysBrand,
  StearnsAndFosterBrand,
  TempurPedicBrand,
  TuftAndNeedleBrand,
  IntellibedBrand,
  TuloBrand,
  React
};