import { Playground, Props } from 'docz';
import { Row } from "../../../../../documentation/components/helpers";
import Headboard from "../../../../../src/Icons/Headboard";
import Comforter from "../../../../../src/Icons/Comforter";
import MattressProtector from "../../../../../src/Icons/MattressProtector";
import Mattress from "../../../../../src/Icons/Mattress";
import Pillow from "../../../../../src/Icons/Pillow";
import Sheets from "../../../../../src/Icons/Sheets";
import * as React from 'react';
export default {
  Playground,
  Props,
  Row,
  Headboard,
  Comforter,
  MattressProtector,
  Mattress,
  Pillow,
  Sheets,
  React
};