import { Playground, Props } from 'docz';
import { Row } from "../../../../../documentation/components/helpers";
import CalKing from "../../../../../src/Icons/CalKing";
import King from "../../../../../src/Icons/King";
import Queen from "../../../../../src/Icons/Queen";
import FullXl from "../../../../../src/Icons/FullXl";
import Full from "../../../../../src/Icons/Full";
import SplitKing from "../../../../../src/Icons/SplitKing";
import Twin from "../../../../../src/Icons/Twin";
import TwinXl from "../../../../../src/Icons/TwinXl";
import * as React from 'react';
export default {
  Playground,
  Props,
  Row,
  CalKing,
  King,
  Queen,
  FullXl,
  Full,
  SplitKing,
  Twin,
  TwinXl,
  React
};