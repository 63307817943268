import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const Queen: React.FunctionComponent<Props> = ({ width = '18', height = '24', fillColor = '#2D2926', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 18 24" {...rest}>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M.56 24h16.88c.293 0 .53-.237.53-.53V.53c0-.293-.237-.53-.53-.53H.56C.414-.008.272.044.166.144.06.244 0 .384 0 .53v22.94c0 .146.06.285.166.386.106.1.248.152.394.144zm16.35-1H1.09V1.05h15.82V23zm-1.58-.9H2.67c-.14.003-.274-.051-.374-.149-.1-.098-.156-.232-.156-.371V9.15c0-.293.237-.53.53-.53h12.66c.293 0 .53.237.53.53v12.43c0 .14-.056.273-.156.371-.1.098-.234.152-.374.149zm-.53-1.05H3.2V9.68h11.6v11.37zm-8.03-19H4.32c-.781 0-1.462.532-1.65 1.29l-.59 2.34c-.122.505-.008 1.039.31 1.45.32.416.815.66 1.34.66h3c.935-.006 1.69-.765 1.69-1.7V3.75c0-.92-.73-1.673-1.65-1.7zm.64 4.04V3.75c.001-.34-.26-.624-.6-.65H4.36c-.299-.004-.56.2-.63.49l-.59 2.34c-.043.195 0 .4.12.56.126.151.313.239.51.24h3c.353 0 .64-.287.64-.64zm8.51-.41l-.59-2.34c-.188-.758-.869-1.29-1.65-1.29h-2.45c-.935.005-1.69.765-1.69 1.7v2.34c0 .935.755 1.694 1.69 1.7h3c.525 0 1.02-.244 1.34-.66.332-.404.461-.939.35-1.45zm-1.65 1.05c.197-.001.384-.089.51-.24.135-.153.19-.36.15-.56l-.58-2.34c-.07-.29-.331-.494-.63-.49h-2.45c-.355.005-.64.295-.64.65v2.34c0 .353.286.64.64.64h3z"
      clipRule="evenodd"
    />
  </svg>
)

export default Queen
