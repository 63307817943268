import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const PaperMoney: React.FunctionComponent<Props> = ({
  width = '16',
  height = '25',
  fillColor = '#2D2926',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 16 25" {...rest}>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M13.15 0H3C1.62 0 .5 1.12.5 2.5v7c0 .276.224.5.5.5h1.5v13.5c-.002.186.1.358.263.446.164.088.363.078.517-.026l1.22-.82 1.22.82c.17.11.39.11.56 0l1.22-.82 1.22.82c.17.11.39.11.56 0l1.22-.82 1.22.82c.17.11.39.11.56 0l1.22-.82 1.22.82c.085.05.181.078.28.08.084 0 .166-.021.24-.06.16-.09.258-.257.26-.44v-21c.04-1.338-1.012-2.456-2.35-2.5zM2.5 9h-1V2.5c0-.428.181-.835.5-1.12.32.284.502.692.5 1.12V9zm12 13.57l-.72-.49c-.17-.11-.39-.11-.56 0L12 22.9l-1.22-.82c-.171-.107-.389-.107-.56 0L9 22.9l-1.22-.82c-.17-.11-.39-.11-.56 0L6 22.9l-1.22-.82c-.17-.11-.39-.11-.56 0l-.72.49V2.5c0-.54-.175-1.067-.5-1.5h10.15c.786.043 1.39.714 1.35 1.5v20.07zM13 3H5c-.276 0-.5.224-.5.5s.224.5.5.5h8c.276 0 .5-.224.5-.5S13.276 3 13 3zm0 3H5c-.276 0-.5.224-.5.5s.224.5.5.5h8c.276 0 .5-.224.5-.5S13.276 6 13 6zm0 3H5c-.276 0-.5.224-.5.5s.224.5.5.5h8c.276 0 .5-.224.5-.5S13.276 9 13 9zm-3.5 3.92v-.34c0-.276-.224-.5-.5-.5s-.5.224-.5.5V13c-.7.17-1.192.8-1.19 1.52.025.595.329 1.143.82 1.48l1.29.92c.17.123.267.321.26.53 0 .38-.26.81-.62.81 0 0-.85 0-.85-.66 0-.276-.224-.5-.5-.5s-.5.224-.5.5c.008.761.544 1.415 1.29 1.57v.55c0 .276.224.5.5.5s.5-.224.5-.5v-.54c.72-.274 1.192-.97 1.18-1.74 0-.527-.253-1.022-.68-1.33l-1.29-.92c-.25-.151-.408-.418-.42-.71 0-.45.41-.61.77-.61.36 0 .71.22.71.74 0 .276.224.5.5.5.133-.003.259-.058.35-.153.093-.096.143-.224.14-.357.067-.798-.475-1.52-1.26-1.68z"
      clipRule="evenodd"
    />
  </svg>
)

export default PaperMoney
