import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import mattressIpsum from 'mattress-ipsum';
import { Modal, Button } from "../../../../../src";
import * as React from 'react';
export default {
  Playground,
  Props,
  Component,
  mattressIpsum,
  Modal,
  Button,
  React
};