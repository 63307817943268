import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const Sofa: React.FunctionComponent<Props> = ({ width = '26', height = '15', fillColor = '#2D2926', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 26 15" {...rest}>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M22.96 7.184c.303-.172.644-.262.99-.26h.023c.498-.01.981.177 1.35.52.383.352.581.868.534 1.393-.047.524-.334.994-.774 1.268-.052.037-.082.097-.083.161V13.9c-.028.447-.402.788-.84.767h-2.108c-.143.004-.265-.107-.277-.253v-.767c0-.046-.018-.09-.05-.122-.034-.032-.078-.049-.123-.047H4.435c-.078.016-.133.088-.127.169v.767c-.004.07-.036.138-.088.185-.052.047-.12.072-.19.068h-2.1c-.44.022-.815-.319-.847-.767v-3.626c.016-.08-.017-.162-.083-.207C.543 9.764.262 9.25.25 8.694c.015-.658.392-1.252.975-1.533.603-.3 1.31-.282 1.897.046V2.783c.028-.638.302-1.24.764-1.671C4.347.68 4.958.454 5.582.483H20.5c1.293-.06 2.391.956 2.46 2.277v4.424zM4 2.569c.036-.727.639-1.288 1.35-1.258h15.383c.71-.03 1.313.53 1.35 1.258v5.198c-.002.054-.026.105-.066.141-.04.036-.091.055-.145.05H4.232c-.056.012-.115-.003-.16-.04-.044-.037-.07-.092-.072-.151V2.569zm8.484 2.11c-.024-.345.224-.647.561-.684.366-.018.677.27.697.644-.002.346-.27.629-.61.64-.338.01-.624-.254-.648-.6zm11.609 5.365c-.007-.199.106-.382.285-.46.325-.196.52-.557.51-.943-.047-.56-.505-.99-1.054-.99-.55 0-1.008.43-1.054.99v1.748c-.03.176-.172.176-.338.176H3.482c-.165 0-.337-.046-.337-.176V8.687c-.012-.273-.129-.53-.326-.714-.197-.184-.457-.28-.724-.268-.557-.026-1.03.413-1.058.982.042.415.309.771.69.92.178.08.29.262.286.46v3.58c.007.1.089.177.187.176h1.245v-1.127c-.006-.02-.006-.041 0-.061.021-.011.046-.011.068 0h19.08c.044 0 .082.032.09.077v1.127h1.222c.098 0 .18-.077.188-.177v-3.618zM4 8.794h8.332c.074-.005.146.024.197.079.051.054.075.13.066.205v.337c.012.076-.011.153-.063.208-.051.056-.125.084-.2.076H4.18c-.04 0-.08-.014-.112-.039-.039-.018-.064-.056-.068-.1v-.766zm18.06.767v-.767h-8.31c-.075-.008-.149.02-.2.075-.052.056-.075.133-.063.209v.337c-.009.075.015.15.066.205.051.055.123.084.197.079h8.152c.041 0 .08-.014.113-.039.03-.024.046-.06.045-.1z"
      clipRule="evenodd"
    />
  </svg>
)

export default Sofa
