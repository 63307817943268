import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const SlowMotion: React.FunctionComponent<Props> = ({
  width = '24',
  height = '22',
  fillColor = '#2D2926',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 24 22" {...rest}>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M14.887 2.265C15.371 1.094 16.513.33 17.78.33c.83-.003 1.625.325 2.213.91.587.586.917 1.38.917 2.21.004 1.267-.756 2.411-1.925 2.899-1.17.488-2.517.222-3.415-.672-.897-.895-1.167-2.242-.683-3.412zm2.893-.935c.863-.004 1.644.514 1.976 1.31.332.797.15 1.716-.46 2.326-.61.61-1.529.792-2.326.46-.797-.332-1.314-1.113-1.31-1.976.005-1.168.951-2.114 2.12-2.12zM5.35 8.35c0-1.657 1.343-3 3-3s3 1.343 3 3-1.343 3-3 3-3-1.343-3-3zm3-2c1.105 0 2 .896 2 2 0 1.105-.895 2-2 2s-2-.895-2-2c0-1.104.895-2 2-2zm4.45 1.01h10.72c.13.003.254.057.344.15.09.094.139.22.136.35v12.31c.011.814-.637 1.484-1.45 1.5H1.45c-.813-.016-1.461-.686-1.45-1.5V7.86c-.003-.13.046-.256.136-.35.09-.093.214-.147.344-.15H3.8c.253.003.465.19.5.44v.56c0 2.21 1.79 4 4 4s4-1.79 4-4v-.45c-.011-.14.036-.279.131-.383.095-.104.228-.165.369-.167zm10.06 13.163c.092-.094.143-.221.14-.353V8.36h-9.7c0 2.761-2.239 5-5 5s-5-2.239-5-5H.96v11.81c-.003.132.048.259.14.353.092.094.218.147.35.147h21.06c.132 0 .258-.053.35-.147z"
      clipRule="evenodd"
    />
  </svg>
)

export default SlowMotion
