import { Playground, Props } from 'docz';
import mattressIpsum from 'mattress-ipsum';
import { Accordion, AccordionItem } from "../../../../../src";
import MediumBed from "../../../../../src/Icons/MediumBed";
import PlushBed from "../../../../../src/Icons/PlushBed";
import CustomAccordion from "../../../../../documentation/components/helpers/CustomAccordion";
import * as React from 'react';
export default {
  Playground,
  Props,
  mattressIpsum,
  Accordion,
  AccordionItem,
  MediumBed,
  PlushBed,
  CustomAccordion,
  React
};