import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const SplitKing: React.FunctionComponent<Props> = ({ width = '24', height = '24', fillColor = '#2D2926', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...rest}>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M9.92 23.88H1.57c-.867 0-1.57-.703-1.57-1.57V1.69C0 .823.703.12 1.57.12h8.35c.863.006 1.56.707 1.56 1.57v20.62c0 .863-.697 1.565-1.56 1.57zm4.16 0h8.35c.867 0 1.57-.703 1.57-1.57V1.69c0-.867-.703-1.57-1.57-1.57h-8.35c-.863.006-1.56.707-1.56 1.57v20.62c0 .863.697 1.565 1.56 1.57zm-.52-22.19c0-.287.233-.52.52-.52h8.35c.14-.003.274.051.374.149.1.098.156.231.156.371v20.62c0 .14-.056.274-.156.371-.1.098-.234.152-.374.15h-8.35c-.287 0-.52-.234-.52-.52V1.69zM1.17 1.304c.108-.1.254-.148.4-.134h8.31c.287 0 .52.233.52.52v20.62c0 .287-.233.52-.52.52H1.53c-.14.003-.274-.05-.374-.149-.1-.097-.156-.231-.156-.37V1.69c0-.148.06-.288.17-.387zM8.87 22H2.61c-.138 0-.27-.055-.368-.152-.097-.098-.152-.23-.152-.368V9.26c0-.287.233-.52.52-.52h6.26c.14-.003.274.051.374.149.1.098.156.231.156.371v12.22c0 .14-.056.274-.156.371-.1.098-.234.152-.374.15zm-.52-1H3.13V9.78h5.22V21zM3.84 2.14c-.74-.003-1.384.502-1.56 1.22l-.57 2.29c-.12.47-.013.97.29 1.35.304.393.773.622 1.27.62h5c.495 0 .963-.23 1.267-.62.305-.39.413-.9.293-1.38l-.57-2.29c-.176-.718-.82-1.223-1.56-1.22l-3.86.03zm-.62 4.58h4.974c.2-.003.39-.097.515-.256.13-.165.174-.381.121-.584l-.57-2.3c-.077-.303-.348-.516-.66-.52H3.79c-.314 0-.586.215-.66.52l-.58 2.3c-.05.203-.001.418.13.58.128.168.329.265.54.26zM21.39 22h-6.26c-.14.003-.275-.05-.374-.149-.1-.097-.156-.231-.156-.37V9.26c0-.14.056-.274.156-.372.1-.098.234-.152.374-.149h6.26c.287 0 .52.233.52.52v12.22c0 .287-.233.52-.52.52zm-.52-1h-5.22V9.78h5.22V21zM16.35 2.14c-.74-.003-1.384.502-1.56 1.22l-.57 2.29c-.12.48-.012.99.293 1.38.304.39.772.62 1.267.62h4.95c.496-.001.963-.23 1.27-.62.305-.39.416-.898.3-1.38l-.58-2.29c-.176-.718-.82-1.223-1.56-1.22h-3.81zm-.57 4.58h4.95c.211.005.412-.092.54-.26.131-.162.18-.377.13-.58l-.58-2.3c-.074-.305-.346-.52-.66-.52h-3.81c-.312.004-.583.217-.66.52l-.57 2.3c-.053.203-.008.42.121.584.13.165.33.26.539.256z"
      clipRule="evenodd"
    />
  </svg>
)

export default SplitKing
