import { Playground, Props } from 'docz';
import { Row } from "../../../../../documentation/components/helpers";
import ChatBubble from "../../../../../src/Icons/ChatBubble";
import Location from "../../../../../src/Icons/Location";
import Phone from "../../../../../src/Icons/Phone";
import Email from "../../../../../src/Icons/Email";
import CheckmarkOutline from "../../../../../src/Icons/CheckmarkOutline";
import * as React from 'react';
export default {
  Playground,
  Props,
  Row,
  ChatBubble,
  Location,
  Phone,
  Email,
  CheckmarkOutline,
  React
};