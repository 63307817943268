import { Playground, Props } from 'docz';
import { Row } from "../../../../../documentation/components/helpers";
import Chair from "../../../../../src/Icons/Chair";
import MassageChair from "../../../../../src/Icons/MassageChair";
import Sofa from "../../../../../src/Icons/Sofa";
import Futon from "../../../../../src/Icons/Futon";
import * as React from 'react';
export default {
  Playground,
  Props,
  Row,
  Chair,
  MassageChair,
  Sofa,
  Futon,
  React
};